import React from "react";
import { Modal, StackingLayout, Alert, LocalTable, FlexLayout, FlexItem, Button } from "@nutanix-ui/prism-reactjs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { putActionSelected } from "../../../../Service/dgo";

export default function SelectedActionModal({ visible, handleClose, rejectedList, approvedList, holdList, batch_id, duns, listType }) {
    const history = useHistory();

    const handleSelectedActionSubmit = () => {
        putActionSelected(approvedList, rejectedList, holdList, batch_id, duns, listType)
        .then(resp => {
            history.push('/dgo', {});
        })
    }

    return <Modal
        visible={visible}
        width={'80%'}
        title={<div style={{ display: 'flex', justifyContent: "flex-start" }}>Summary</div>}
        onClose={handleClose}
        restoreFocus={true}
        closeButtonProps={{ 'data-test': 'my-close-icon-data-test' }}
        primaryButtonOnClick={handleClose}
        footer={null}
    >
        <StackingLayout padding="20px">
            <Alert
                type={Alert.AlertTypes.WARNING}
                data-test-id="type-warning"
                aria-live="off"
                message="Please confirm the audit one more time. After clicking the submit button, actions cannot be retrieved." />
            <RejectedTable data={rejectedList} />
            <ApprovedTable data={approvedList} />
            <HoldTable data={holdList} />
            <FlexLayout justifyContent="flex-end">
                <FlexItem>
                    <Button onClick={handleClose}>
                        Back
                    </Button>
                </FlexItem>
                <FlexItem>
                    <Button onClick={handleSelectedActionSubmit}>
                        Submit
                    </Button>
                </FlexItem>
            </FlexLayout>
        </StackingLayout>
    </Modal>
}


function RejectedTable({ data }) {

    const columns = [{
        title: 'Attribute Name',
        key: 'attribute_name'
    },
    {
        title: 'Previous',
        key: 'previous',
        render: (value, object, index) => <p className='sfdc_id_columns'>{object.previous}</p>
    },
    {
        title: 'Current',
        key: 'current',
        render: (value, object, index) => <p className='sfdc_id_columns'>{object.current}</p>
    },
    ];

    const topSection = {
        title: <div style={{marginTop : '1rem'}}> 
        Rejected
    </div>
    };

    return <div className="overviewChangesContainer">
        <LocalTable
            columns={columns}
            dataSource={data}
            topSection={topSection}
        />
    </div>
}

function ApprovedTable({ data }) {

    const columns = [{
        title: 'Attribute Name',
        key: 'attribute_name'
    },
    {
        title: 'Previous',
        key: 'previous',
        render: (value, object, index) => <p className='sfdc_id_columns'>{object.previous}</p>
    },
    {
        title: 'Current',
        key: 'current',
        render: (value, object, index) => <p className='sfdc_id_columns'>{object.current}</p>
    },
    ];

    const topSection = {
        title: <div style={{marginTop : '1rem'}}> 
            Approved
        </div>
    };

    return <div className="overviewChangesContainer">
        <LocalTable
            columns={columns}
            dataSource={data}
            topSection={topSection}
        />
    </div>
}

function HoldTable({ data }) {

    const columns = [{
        title: 'Attribute Name',
        key: 'attribute_name'
    },
    {
        title: 'Previous',
        key: 'previous',
        render: (value, object, index) => <p className='sfdc_id_columns'>{object.previous}</p>
    },
    {
        title: 'Current',
        key: 'current',
        render: (value, object, index) => <p className='sfdc_id_columns'>{object.current}</p>
    },
    ];

    const topSection = {
        title: <div style={{marginTop : '1rem'}}> 
        Hold
    </div>
    };

    return <div className="overviewChangesContainer">
        <LocalTable
            columns={columns}
            dataSource={data}
            topSection={topSection}
        />
    </div>
}
