import React, { useCallback, useEffect, useState } from "react";
import _ from 'lodash';

import '../../styles.css';

import EventTypeFilter from "./EventTypeFilter";
import { getSubRegionList } from "../../../../Service/dgo";

export default function RegionFilter({ defaultValue, loading, optionList, setdefaultValue, subRegionDefaultValue, subRegionSetValue }) {

    const [regionValue, setRegionValue] = useState(defaultValue || null);
    const [subRegionOptionList, setSubRegionOptionList] = useState([]);
    const [subRegionLoading, setSubRegionLoading] = useState(true);

    useEffect(() => {
        setdefaultValue(regionValue); // Remove prevState; not necessary here
        setSubRegionOptionList([]); // Reset to an empty array before fetching
        debouncedSearch(regionValue);
    }, [regionValue]);

    const debouncedSearch = useCallback(
        _.debounce((data) => {
            data.forEach(ob => {
                setSubRegionLoading(true);
                fetchSubRegionData(ob.value);
            });
        }, 2000),
        []
    );

    const onlyUnique = (value, index, array) => {
        return array.indexOf(value) === index;
    }

    const generateOptionList = (data) => {
        return data.map((ob, index) => ({
            id: index,
            label: ob.toUpperCase(),
            value: ob
        }));
    }

    const fetchSubRegionData = async (region) => {
        try {
            const res = await getSubRegionList(region);
            if (res.length > 0) {
                const updatedSubRegion = subRegionOptionList;
                res.forEach(ob => {
                    updatedSubRegion.push(ob.sub_region);
                }); // Collect all sub_regions
                const uniqueSubRegions = updatedSubRegion.filter(onlyUnique);
                const generatedList = generateOptionList(uniqueSubRegions);
                setSubRegionOptionList(generatedList); // Directly set unique, generated list
                setSubRegionLoading(false);
            }
        } catch (err) {
            console.log(err);
            setSubRegionLoading(false); // Make sure loading stops in case of error
        }
    }

    return (
        <>
            <EventTypeFilter
                loading={loading}
                label="Region"
                defaultValue={defaultValue}
                setdefaultValue={setRegionValue}
                optionList={optionList}
            />
            <EventTypeFilter
                loading={subRegionLoading}
                label="Sub-region"
                defaultValue={subRegionDefaultValue}
                setdefaultValue={subRegionSetValue}
                optionList={subRegionOptionList}
            />
        </>
    );
}
