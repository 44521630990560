import React from "react";
import { Badge, ChevronLeftIcon, FlexLayout, Separator, VerticalSeparator } from "@nutanix-ui/prism-reactjs";

import '../../styles.css';

export default function ViewDUNChangesHeader() {
    return <div className="viewChangesHeaderContainer">
        <div>
            <FlexLayout alignItems="center">
                <Separator separator={<VerticalSeparator data-testid='separator' />}>
                    <a href="/dgo"><ChevronLeftIcon size='large' /></a>
                    <Badge
                        color="gray"
                        count="Dashboard"
                    />
                </Separator>
                <h3 data-testid='header'>Changes Audit</h3>
            </FlexLayout>
        </div>
    </div>
}