import { SERVER_URL } from "../../config/config";
import axios from "axios";


export const getDGOToken = async () => {
    try {
        return await axios.post(`${SERVER_URL}/getDGOToken`)
            .then(resp => {
                return resp;
            })
            .catch(err => {
                throw new Error({ message: err });
            });
    } catch (err) {
        throw new Error({ message: err });
    }
}

export const getUserInfo = async () => {
    try {
        return axios.get(`${SERVER_URL}/get_user_setting`)
            .then(resp => {
                return resp?.data?.data;
            })
            .catch(err => {
                throw new Error({ message: err });
            });
    } catch (err) {
        throw new Error({ message: err });
    }
}

export const getStats = async () => {
    try {
        return axios.get(`${SERVER_URL}/get_stats`)
            .then(resp => {
                return resp.data.data;
            })
            .catch(err => {
                throw new Error({ message: err });
            });
    } catch (err) {
        throw new Error({ message: err });
    }
}

export const getFilterData = async () => {
    try {
        return axios.get(`${SERVER_URL}/get_filters_data`)
            .then(resp => {
                return resp.data.data;
            })
            .catch(err => {
                throw new Error({ message: err });
            });
    } catch (err) {
        throw new Error({ message: err });
    }
}

export const getRecordData = async (filters) => {
    try {
        const request1 = axios.get(`${SERVER_URL}/get_record`, {
            params: {
                filters: filters,
                action: "ALL"
            }
        });
        const request2 = axios.get(`${SERVER_URL}/get_record`, {
            params: {
                filters: filters,
                action: "PENDING"
            }
        });
        const request3 = axios.get(`${SERVER_URL}/get_record`, {
            params: {
                filters: filters,
                action: "HOLDING"
            }
        });
        const request4 = axios.get(`${SERVER_URL}/get_record`, {
            params: {
                filters: filters,
                action: "APPROVED"
            }
        });
        const request5 = axios.get(`${SERVER_URL}/get_record`, {
            params: {
                filters: filters,
                action: "REJECTED"
            }
        });
        return axios.all([request1, request2, request3, request4, request5])
            .then(axios.spread((response1, response2, response3, response4, response5) => {
                return {
                    all_list: response1.data.data,
                    pending_list: response2.data.data,
                    hold_list: response3.data.data,
                    approved_list: response4.data.data,
                    rejected_list: response5.data.data
                }
            }))
            .catch(error => {
                console.error('Error in one of the requests:', error);
            });
    } catch (err) {
        throw new Error({ message: err });
    }
}

export const getDunsChanges = async (duns, batch_id) => {
    try {
        return axios.get(`${SERVER_URL}/get_duns_changes`, {
            params: {
                duns: duns,
                batch_id: batch_id
            }
        })
            .then(resp => {
                return mapViewDunsChanges(resp.data.data);
            })
            .catch(err => {
                throw new Error({ message: err });
            });
    } catch (err) {
        throw new Error({ message: err });
    }
}

export const getAvailableAttributes = async () => {
    try {
        return axios.get(`${SERVER_URL}/get_available_attributes`)
            .then(resp => {
                return resp.data.data;
            })
            .catch(err => {
                throw new Error({ message: err });
            });
    } catch (err) {
        throw new Error({ message: err });
    }
}

export const getSubRegionData = async () => {
    try {
        return axios.get(`${SERVER_URL}/get_sub_region`)
            .then(resp => {
                return resp.data.data;
            })
            .catch(err => {
                throw new Error({ message: err });
            });
    } catch (err) {
        throw new Error({ message: err });
    }
}

export const putActionAll = async (action, duns, reason, batch_id, listType) => {
    try {
        return axios.post(`${SERVER_URL}/submit_duns_action`, {
            reason: reason,
            action: action,
            batch_id: batch_id,
            duns: duns,
            listType: listType
        })
            .then(resp => {
                return resp.data.data;
            })
            .catch(err => {
                throw new Error({ message: err });
            });

    } catch (err) {
        throw new Error({ message: err });
    }
}

export const putActionSelected = async (approvedList, rejectedList, holdList, batch_id, duns, listType) => {
    let request_payload = [];
    approvedList.forEach(obj => {
        request_payload.push(obj.attribute_name)
    });
    if (request_payload.length > 0) {
        await axios.post(`${SERVER_URL}/submit_selected_duns_action`, {
            attribute: request_payload,
            action: "APPROVED",
            batch_id: batch_id,
            duns: duns,
            listType: listType
        })
    }
    request_payload = [];
    rejectedList.forEach(obj => {
        request_payload.push(obj.attribute_name)
    });
    if (request_payload.length > 0) {
        await axios.post(`${SERVER_URL}/submit_selected_duns_action`, {
            attribute: request_payload,
            action: "REJECTED",
            batch_id: batch_id,
            duns: duns,
            listType: listType
        })
    }
    request_payload = [];
    holdList.forEach(obj => {
        request_payload.push(obj.attribute_name)
    });
    if (request_payload.length > 0) {
        await axios.post(`${SERVER_URL}/submit_selected_duns_action`, {
            attribute: request_payload,
            action: "HOLDING",
            batch_id: batch_id,
            duns: duns,
            listType: listType
        })
    }
}

export const getSubRegionList = async (region) => {
    try {
        return axios.get(`${SERVER_URL}/get_subregion_data`, {
            params: {
                region: region
            }
        })
            .then(resp => {
                return resp.data.data
            })
            .catch(err => {
                throw new Error({ message: err });
            });
    } catch (err) {
        throw new Error({ message: err });
    }
}

const mapViewDunsChanges = (data) => {
    const response = [];
    let i = 0;
    data.forEach(ob => {
        response.push({
            key: i++,
            attribute_name: ob.attribute_name,
            previous: ob.previous,
            current: ob.current,
            isHold: false,
            isApprove: false,
            isReject: false
        })
    });

    return response;
}