import React, { useState, useContext, useEffect } from "react";
import {
  Modal,
  Button,
  StackingLayout,
  FormItemTextArea,
  FormItemInput,
  FormItemSelect,
  Form,
  FormSection,
  TextLabel,
  FlexLayout,
  FlexItem,
  PlusIcon,
  CloseIcon,
  FormItemProvider,
  MultiSelect
} from "@nutanix-ui/prism-reactjs";

import {
  ETLCanvasContext,
  ETLCanvasContextDispatcher,
} from "../../config/contexts";

import axios from "axios";
import { SERVER_URL } from "../../config/config";
export default function ConnectorProcessForm(props) {
  const SelectWithLabel = FormItemProvider(MultiSelect);

  const { data, schema, origin, handleconfigProcessorSave } = props;
  
  const {pipelineData} = useContext(ETLCanvasContext);
  const {processorConfigForm} = useContext(ETLCanvasContext);
  const {setPipelineData} = useContext(ETLCanvasContextDispatcher);
  const queryParameters = new URLSearchParams(window.location.search);
  const projectID = queryParameters.get("projectID");
  const pipeLineID = queryParameters.get("pipelineID");
  useEffect(() => {
    setPipelineData({
      ...pipelineData,
      pipelineID: pipeLineID,
      project_id: projectID,
    }); // eslint-disable-next-line
  }, []);

  const [schemaMapping, setSchemaMapping] = useState(null);

  const [schemaRowsData, setSchemaRowsData] = useState(schemaMapping || []);

  const [schemaAndTableData, setSchemaAndTableData] = useState([]);

  const [schemaLoading, setSchemaLoading] = useState(true);
  const [tableColsLoading, setTableColsLoading] = useState(true);

  const [schemaData, setSchemaData] = useState([]);
  const [searchSchemaData, setSearchSchemaData] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState(null);

  const [selectedTableName, setSelectedTableName] = useState(null);
  const [tableNameData, setTableNameData] = useState([]);
  const [searchTableNameData, setSearchTableNameData] = useState([]);


  const [tableColsNameData, setTableColsNameData] = useState([]);
  const [searchTableColsNameData, setSearchTableColsNameData] = useState([]);
  const [selectedTableColsName, setSelectedTableColsName] = useState(null);

  const [selectedColumns, setSelectedColumns] = useState(data?.columnsData || []);

  const [selectedOffsetCol, setSelectedOffsetCol] = useState(null);
 
  const [selectedOperation, setSelectedOperation] = useState(null);

  const [schemaText, setSchemaText] = useState("");

  const [selectedLoadType, setSelectedLoadType] = useState(null);
  const [selectedStartingOffset, setSelectedStartingOffset] = useState(null);

  const [DEFAULT_VALIDATIONS, setDefaultValidations] = useState([]);
  const [defaultValues, setDefaultValues] = useState({});
  const addSchemaRows = async () => {
    let obj = { key: schemaText };
    obj[selectedTableColsName.label] = obj["key"];
    delete obj["key"];
    schemaRowsData.push(obj);
    setSchemaRowsData(schemaRowsData);
    setSchemaMapping(schemaRowsData)
    setSelectedTableColsName(null);
    setSchemaText("");
  };
  const handleEditSchemaRows = (objKVType, value, i) => {
    let key = Object.keys(schemaRowsData[i])[0];
    if (objKVType === "value") {
      schemaRowsData[i][key] = value;
    } else {
      schemaRowsData[i][value.label] = schemaRowsData[i][key];
      delete schemaRowsData[i][key];
    }
  };
  const removeSchemaRows = (index) => {
    // eslint-disable-next-line
    const newRows = schemaRowsData.filter((sale, i) => i != index);
    setSchemaRowsData(newRows);
    setSchemaMapping(newRows)
  };



  useEffect(() => {

      var validationKey=`${[Form.VALIDATION_RULE.REQUIRED]}`;
      let connectionName=schema?.value ? schema?.value :  data?.schemaValue
      const setProcessorConfigForm=processorConfigForm?.find(item=>item.value ===connectionName)
    const { constructDefaultValidations, constructDefaultValues } = setProcessorConfigForm?.formData?.etl_fields&& setProcessorConfigForm?.formData?.etl_fields?.reduce((acc, item) => {
        if (item.key !== 'columns' && item.key !== 'schema_config') {
          if(item.required){
            acc.constructDefaultValidations[item.key === 'schema' ?"schemaForm":item.key] = {
                message: `${item.key === 'schema' ? "schemaForm" : item.label} is required`,
                [validationKey]: item.required
            };}
        }
        acc.constructDefaultValues[item.key === 'schema' ?"schemaForm":item.key] = 
        (data && data[item.key] ? 
            (item.key === 'schema'&& item.type ==="dd_half" ? data['schemaForm']:
             data[item.key]) :
             ["dd_half", "dd_full" , "dd_static_half", "dd_static_full"].includes(item.type)  ? null :  item?.defaultValue);
        acc.constructDefaultValues["id"] = schema?.id || data?.id;//columnsData
        if(item.key ==="query"){
          acc.constructDefaultValues["query"] = data?.query?.pipeLine?.queryData ? atob(data.query?.pipeLine?.queryData?.value) : ''
        }
        if(item.key ==="columns"){
          acc.constructDefaultValues["columns"] = data?.columnsData || null
        }
        return acc;
    }, { constructDefaultValidations: {}, constructDefaultValues: {} }) || { constructDefaultValidations: {}, constructDefaultValues: {} };
    
    setDefaultValidations([constructDefaultValidations]);
    setDefaultValues(constructDefaultValues);
  }, [data?.alias || processorConfigForm || schema ||  DEFAULT_VALIDATIONS || defaultValues]);
    
  
  useEffect(() => {
      let tableData={
        key: data?.table?.key,
        label: data?.table?.label,
      };
      let schemaData= {
        key: data?.schemaForm?.key,
        label: data?.schemaForm?.label,
      };
      let dataLoadType= {
        key: data?.data_load_type ?.key ||  "",
        label: data?.data_load_type ?.key || "",
      };
      let offsetColData= {
        key: data?.offset_col ? data?.offset_col?.key : "",
        label: data?.offset_col ? data?.offset_col?.label : "",
      };
      let operationData= {
        key: data?.operation ? data?.operation?.key : "",
        label: data?.operation ? data?.operation?.label : "",
      };
      let startingOffset= {
        key: data?.starting_offset ? data?.starting_offset?.key : "",
        label: data?.starting_offset ? data?.starting_offset?.label : "",
      };
      let arugementsArray=[
        [schemaData,'schema'],
        [tableData,'table'],
        [offsetColData,'offset_col'],
        [dataLoadType,'data_load_type'],
        [operationData, 'operations'],
        [startingOffset, 'starting_offset']
      ]
      if(data){
      arugementsArray?.map(args=>{

          handleSelectChange(...args);
      })
      setSchemaMapping(
        data?.schemaMapping
          ? JSON.parse(data?.schemaMapping)
          : data?.schema_mapping
          ? JSON.parse(data?.schema_mapping)
          : ""
      );}
    }, [data,schemaAndTableData]);
  useEffect(() => {
    fetchSchemaAndTable(); // eslint-disable-next-line
  }, [ schema || data]);
  
  const fetchSchemaAndTable = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const projectID = queryParameters.get("projectID");
    if (schema || data?.schemaValue) {
      axios
        .post(`${SERVER_URL}/fetchSchemaAndTable`, {
          project_id: projectID,
          conn_name: schema?.value ? schema?.value : data?.schemaValue,
        })
        .then((resp) => {
          if (resp?.data?.data?.res) {
            constructSchemaData(resp.data.data.res);
          }
        });
    }
  };
  const constructSchemaData = (res) => {
    
    let tempData = [];
    let schemaKeys = Object.keys(res);
    schemaKeys?.forEach((o) => {
      tempData.push({
        key: o,
        label: o,
        rowProps: {
          "data-testid": o,
        },
      });
    });
    setSchemaData((prevState) => tempData);
    setSearchSchemaData((prevState) => tempData);
    setSchemaLoading((prevState) => false);
    setSchemaAndTableData((prev) => res);

  };
  const handleTableNameChange = (item) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const projectID = queryParameters.get("projectID");
    let payload = {
      project_id: projectID,
      conn_name: schema?.value || data?.schemaValue,
      schema_name: selectedSchema?.label || data?.schemaForm?.label,
      object_name: item?.label || data?.table?.label,
    };
    setSelectedTableName((prevState) => item);
    if(Object.keys(payload).every(key => !!payload[key])){

        getTableColumns(payload);
    }
  };
  const getTableColumns = (payload) => {
    
    try {
      axios.post(`${SERVER_URL}/getColsMetadataModel`, payload).then((resp) => {

        if (resp?.data && resp?.data?.data?.res) {
          setTableColsLoading(false);
          const tableColKeys = Object.keys(resp?.data?.data?.res);
          let TablleColsData = [];
          tableColKeys?.forEach((o, i) => {
            TablleColsData.push({
              id: ++i,
              key: o,
              label: o,
              rowProps: {
                "data-testid": o,
              },
            });
          });
          setTableColsNameData((prevState) => TablleColsData);
          setSearchTableColsNameData((prevState) => TablleColsData);
        }
      });
    } catch (err) {
    }
  };
  const handleOnInputValueChange = (
    event,
    searchArray,
    setSearchArray,
    setSelectedValue
  ) => {
    if (event.target.value.length > 0) {
      if (searchArray?.length === 0) {
        setSchemaLoading(false);
        setTableColsLoading(false);
      }
      const newRows = searchArray?.filter((row) => {
        return row.label
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      }) || [];
      newRows.unshift({ key: event.target.value, label: event.target.value });
      setSearchArray(newRows);
      setSelectedValue({ key: event.target.value, label: event.target.value });
    } else if (event.target.value.length === 0) {
      setSearchArray(searchArray);
      setSelectedValue(null);
    }
  };
  const handleSchemaChange = (item) => {
    item !== null && setSelectedSchema((prevState) => item);
    const tableKeys =
      schemaAndTableData && schemaAndTableData[item.key] && Object.keys(schemaAndTableData[item.key]) || [];
      let tempData = [];
    tableKeys.length > 0 &&
      tableKeys?.forEach((o, i) => {
        tempData.push({
          key: i++,
          label: o,
          rowProps: {
            "data-testid": o,
          },
        });
      });
    setTableNameData((prevState) => tempData || []);
    setSearchTableNameData((prevState) => tempData || []);
  };

  const handleSelectChange = (event, key) => {
    if (key === "schema") {
      if (event) {
        handleSchemaChange(event);
      }
      setSelectedSchema(event);
    }
    if (key === "table") {
      if (event) {
        handleTableNameChange(event);
      }
      setSelectedTableName(event);
    }
    if (key === "offset_col") {
      setSelectedOffsetCol(event);
    }
    if (key === "columns") {
      setSelectedTableColsName(event);
    }
    if(key==='data_load_type'){
        setSelectedLoadType(event)
    }
    if(key === "operations") {
      return setSelectedOperation(event);
  }
    if(key==='starting_offset'){
      setSelectedStartingOffset(event)
  }
  };

  const generateSearchOptions = (key) => {
    if (key === "schema") {
      return searchSchemaData;
    }
    if (key === "table") {
      return searchTableNameData;
    }
    if (key === "columns" || key === "offset_col") {
      return searchTableColsNameData;
    }
  };
  const generateOptions = (key) => {
    if (key === "schema") {
      return schemaData;
    }
    if (key === "table") {
      return tableNameData;
    }
    if (key === "columns" || key === "offset_col") {
      return tableColsNameData;
    }
  };
  const generateSetSearchData = (key) => {
    if (key === "schema") {
      return setSearchSchemaData;
    }
    if (key === "table") {
      return setSearchTableNameData;
    }
    if (key === "columns" || key === "offset_col") {
      return setSearchTableColsNameData;
    }
  };
  const generateSetSelectedFields = (key) => {
    if (key === "schema") {
      return setSelectedSchema;
    }
    if (key === "table") {
      return setSelectedTableName;
    }
    if (key === "offset_col") {
      return setSelectedOffsetCol;
    }
    if (key === "columns") {
      return setSearchTableColsNameData;
    }
    if (key === "data_load_type") {
        return setSelectedLoadType;
      }
    if (key === "operations") {
      return setSelectedOperation;
    }
    if (key === "starting_offset") {
      return setSelectedStartingOffset;
    }
  };
  const handleLables = (key) => {
    if (key === "schema") {
      return selectedSchema || {};
    }
    if (key === "table") {
      return selectedTableName || {};
    }
    if (key === "offset_col") {
      return selectedOffsetCol || {};
    }
    if(key==='data_load_type'){
        return selectedLoadType || {};
    }
    if(key==='operations'){
      return selectedOperation || {};
     }
     if(key==='starting_offset'){
      return selectedStartingOffset || {};
     }
    
  };
  const propsData = {
    rowsData: tableColsNameData || [],
    groupNestedItemsTag: false,
    selectAllOption: true,
  };
  
  return (
    <Modal
      visible={props.visible}
      title={
        (data?.schema || schema?.label) +
          " ( " +
          (data?.schemaValue || schema?.value) +
          ")" || "Schema"
      }
      onClose={props.handleOnClose}
      closeIconProps={{ "data-test": "my-close-icon-data-test" }}
      footer={false}
    >
     {Object.keys(defaultValues).length !== 0 && <StackingLayout padding="20px">
        <Form
          key={ defaultValues?.id || data?.id || schema?.id}
          id={defaultValues?.id || data?.id || schema?.id}
          validations={DEFAULT_VALIDATIONS}
          onSubmit={(values) => {schemaRowsData?.length>0? handleconfigProcessorSave(values, schemaRowsData, selectedColumns): handleconfigProcessorSave(values, schemaMapping, selectedColumns)}}
          defaultValues={defaultValues}
          className="paas-connector-form"
        >
          {(formHelper) => {
            return (
              <FormSection key={ data?.id || schema?.id}  className="paas-content-form-body">
                <FormItemInput
                  id="id"
                  disabled
                  error={false}
                  formHelper={formHelper}
                  label="Input ID"
                />

                {processorConfigForm.find(item=>item.value === (schema?.value ? schema?.value : data?.schemaValue) )?.formData?.etl_fields.map((item,index) => {
                  const searchRowsData = generateSearchOptions(item.key);
                  const rowsData = generateOptions(item.key);

                  const selectedLables = handleLables(item.key);
                  const settingSearchData = generateSetSearchData(item.key);
                  const settingSearchFields = generateSetSelectedFields(
                    item.key
                  );
                  return (
                    <div key={ index}>
                      {item.type === "int_half" ||
                      item.type === "text_full" ||
                      item.type === "int_half" ||
                      item.type === "text_half" ? (
                        <FormItemInput
                          data-testid="paas-etlCanvas-sfdc-alias"
                          id={item.key}
                          formHelper={formHelper}
                          label={item.label}
                        />
                      ) : 
                           item.type == "dd_half" ||  item.type == "dd_full" ||
                           item.type === "dd_static_half" || item.type === "dd_static_full" ? (
                        <>
                          <FormItemSelect
                            data-testid="paas-etlCanvas-sfdc-schemaForm"
                            id={item.key === 'schema' ? "schemaForm" : item.key}
                            rowsData={
                              item.type === "dd_static_half" ||  item.type === "dd_static_full"
                                ? item.dd_data
                                : searchRowsData
                            }
                            selectedRow={
                                selectedLables
                            }
                            loading={(['operations', 'table', 'starting_offset'].includes(item.key))? false : ( item.key === 'schema' || item.key === 'schemaForm') ? schemaLoading : tableColsLoading}
                            formHelper={formHelper}
                            onSelectedChange={(event) =>
                              handleSelectChange(event, item.key)
                            }
                            label={item.label}
                            inputProps={{
                              value:
                                   selectedLables?.label,
                            }}
                            searchable={ item.type === "dd_static_half" ?false : true}
                            onChange={(event) =>{ 
                              handleOnInputValueChange(
                                event,
                                rowsData,
                                settingSearchData,
                                settingSearchFields
                              )}
                            }
                          />
                        </>
                      ): item.type == "textArea_full" ? (
                        <>
                          <FormItemTextArea
                            data-testid = 'paas-etlCanvas-formModal-postgres-query'
                            id={item.key}
                            label= {item.label}
                            formHelper={formHelper}
                            placeholder="Please Type your query here"
                            style={{ height: "10rem" }}
                          />
                        </>
                      ) : (
                        item.type === "multi_select_dd_full" && (
                          <SelectWithLabel
                            data-testid="paas-etlCanvas-snowflake-formModal-columns"
                            selectedRows={selectedColumns || []}
                            onSelectedChange={(rows) => {
                              setSelectedColumns(rows);
                            }}
                            formHelper={ formHelper }
                            formItemProviderProps={{ id: "columns" }}
                            overflowLimit={2}
                            {...propsData}
                            label={"Columns"}
                          />
                        )
                      )}
                    </div>
                  );
                })}
                <StackingLayout>
                  <TextLabel
                    type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}
                    data-test-id="type-primary"
                  >
                    Schema Configuration
                  </TextLabel>
                  {processorConfigForm.find(item=>item.value ===(schema?.value ? schema?.value : data?.schemaValue ))?.formData?.etl_fields.some(
                    (item) => item.type === "schema_kv"
                  ) &&schemaMapping?.length > 0 &&
                    schemaMapping.map((row, i) => (
                      <FlexLayout
                        flexDirection="row"
                        alignItems="flex-end"
                        key={i}
                      >
                        <FormItemSelect
                          data-testid={
                            "paas-etlCanvas-formModal-postgres-addColumn" + i
                          }
                          id={i}
                          selectedRow={{
                            key: Object.keys(row)[0],
                            label: Object.keys(row)[0],
                          }}
                          rowsData={searchTableColsNameData}
                          loading={tableColsLoading}
                          formHelper={formHelper}
                          onSelectedChange={(item) =>
                            handleEditSchemaRows("key", item, i)
                          }
                          label={"Add Columns"}
                        />
                        <FormItemInput
                          data-testid={
                            "paas-etlCanvas-formModal-postgres-addSchema" + i
                          }
                          key={i}
                          label="Add Schema"
                          placeholder="Add Schema"
                          value={Object.values(row)[0]}
                          onChange={(e) =>
                            handleEditSchemaRows("value", e.target.value, i)
                          }
                        />
                        <FlexItem>
                          <Button
                            data-testid={
                              "paas-etlCanvas-formModal-postgres-removeRow" + i
                            }
                            type="secondary"
                            style={{ backgroundColor: "gray" }}
                            onClick={() => removeSchemaRows(i)}
                          >
                            <CloseIcon
                              size="small"
                              aria-hidden="true"
                              color="secondary"
                            />
                          </Button>
                        </FlexItem>
                      </FlexLayout>
                    ))}
                  {processorConfigForm.find(item=>item.value ===(schema?.value ? schema?.value : data?.schemaValue ))?.formData?.etl_fields.some(
                    (item) => item.type === "schema_kv"
                  ) && (
                    <StackingLayout>
                      <FlexLayout flexDirection="row" alignItems="flex-end">
                        <FormItemSelect
                          data-testid="paas-etlCanvas-formModal-postgres-addColumn"
                          id="columns Data"
                          selectedRow={selectedTableColsName}
                          rowsData={searchTableColsNameData}
                          loading={tableColsLoading}
                          formHelper={formHelper}
                          onSelectedChange={(item) =>
                            setSelectedTableColsName(item)
                          }
                          selected={selectedTableColsName}
                          inputProps={{
                            value: selectedTableColsName?.label || "",
                          }}
                          searchable={true}
                          onChange={(event)=>handleOnInputValueChange(event,tableColsNameData,setSearchTableColsNameData,setSelectedTableColsName)}
                          label={"Add Columns"}
                        />
                        <FormItemInput
                          data-testid="paas-etlCanvas-formModal-postgres-addSchema"
                          id="schema"
                          label="Add Schema"
                          placeholder="Add Schema"
                          value={schemaText}
                          onChange={(e) => setSchemaText(e.target.value)}
                        />
                        <FlexItem>
                          <Button
                            onClick={addSchemaRows}
                            data-testid="paas-etlCanvas-formModal-postgres-addRow-btn"
                          >
                            <PlusIcon size="small" aria-hidden="true" />
                          </Button>
                        </FlexItem>
                      </FlexLayout>
                    </StackingLayout>
                  )}

                  {processorConfigForm.find(item=>item.value ===(schema?.value ? schema?.value : data?.schemaValue) )?.formData?.etl_fields.some(
                    (item) => item.type === "schema_plain"
                  ) && (
                    <StackingLayout>
                      <FormItemInput
                        data-testid="paas-etlCanvas-s3Modal-schema"
                        id="schema"
                        label="Object Schema"
                        placeholder="Object Schema"
                        value={schemaMapping?.file_schema || ""}
                        onChange={(e) =>
                          setSchemaMapping({ file_schema: e.target.value })
                        }
                      />
                    </StackingLayout>
                  )}
                </StackingLayout>
                {/* )} */}

                <Button 
                // type="submit"
                onClick={formHelper.handleSubmit}
                  data-testid="paas-etlCanvas-mongo-save-btn"
                >
                  Save
                </Button>
              </FormSection>
            );
          }}
        </Form>
      </StackingLayout>}
    </Modal>
  );
}
