import React, { useEffect, useState } from "react";
import {
  getDGOToken,
  getFilterData,
  getRecordData,
  getUserInfo,
} from "../../Service/dgo";
import { Loader } from "@nutanix-ui/prism-reactjs";

import "./styles.css";

import DGO_Header from "./HeaderDgo";
import DGO_Statistics from "./StatsDGO";
import DGO_Filter_Pane from "./FilterPaneDGO";
import TableTabs from "./TableTab";
export default function DGO() {
  const [loading, setLoading] = useState(true);
  const [listLoading, setListLoading] = useState(true);
  const [filtersData, setFilterData] = useState({});
  const [filtersUpdatedData, setFiltersUpdatedData] = useState(false);
  const [recordData, setRecordData] = useState({});

  useEffect(() => {
    getDGOToken()
      .then((resp) => {
        getUserInfo()
          .then((resp) => {
            if (resp === false) {
              window.location.replace("/unauthorised");
            }
            getFilterData()
              .then((res) => {
                setLoading((prevState) => false);
                setFilterData((prevState) => res);
              })
              .catch((err) => {
                window.location.replace("/error");
              });
          })
          .catch((err) => {
            window.location.replace("/error");
          });
      })
      .catch((err) => {
        window.location.replace("/error");
      });
  }, []);

  useEffect(() => {
    setListLoading((prevState) => true);
    if (Object.keys(filtersUpdatedData).length > 0) {
      getRecordData(filtersUpdatedData)
        .then((res) => {
          setRecordData((prevState) => res);
          setListLoading((prevState) => false);
        })
        .catch((err) => window.location.replace("/error"));
    } else if (Object.keys(filtersData).length > 0) {
      getRecordData({})
        .then((res) => {
          setRecordData((prevState) => res);
          setListLoading((prevState) => false);
        })
        .catch((err) => window.location.replace("/error"));
    }
  }, [filtersUpdatedData, filtersData]);

  if (loading) {
    return (
      <div className="dgo_loading_container">
        <Loader loading={loading} tip="Loading..." data-test-id="overlay" />
      </div>
    );
  }

  return (
    <div className="dgo_main_container">
        <DGO_Header />
        <DGO_Statistics />
        <DGO_Filter_Pane
          data={filtersData}
          setFiltersUpdatedData={setFiltersUpdatedData}
        />
        <TableTabs recordData={recordData} loading={listLoading} />
    </div>
  );
}
