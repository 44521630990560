import  { useState } from "react";
import {  Button, Input } from "antd";
import axios from "axios";
import { Alert } from '@nutanix-ui/prism-reactjs';
import { useNextGenCreateProject } from '../../hooks'

import { apiResponseError } from "../../config/helper";
import { SERVER_URL } from "../../config/config";

import "./NextGenEtl.css";

const ProjectDetails = (props) => {
    const { onCancel, onNext } = { ...props }
    const [projectName, setProjectName] = useState("")
    const [description, setDescription] = useState("")
    const [apiProgress, setApiProgress] = useState({ loading: false, ready: false, error: false })
    const [message, setMessage] = useState('')
    const [nextButtonEnable, setNextButtonEnable] = useState(false)
    const [saveButton,setSaveButton] = useState(true)
    const { TextArea } = Input;
    const { setProjectDetails } = useNextGenCreateProject()

    const addProjectDetails = async (e) => {
        try {

            setApiProgress({ loading: true, ready: false, error: false, info: true, msg: 'Project Creation Request has been Submitted' })
            let uname = localStorage.getItem("email")
            let project_details = {
                team_id: "83f153",
                name: projectName,
                description: description,
                created_by: uname

            }
            const data = await axios.post(`${SERVER_URL}/createProjectModel`, project_details)
            setMessage(data.data.msg)
            // setProjectId(data.data.project_id)
            setProjectDetails(data.data)
            setApiProgress({ loading: false, ready: true, error: false })
            setSaveButton(false)
            setNextButtonEnable(true)
        } catch (e) {
            setApiProgress({ loading: false, ready: false, error: apiResponseError(e) })
        }
    }
    const handleValueChange = (event, value) => {
        setNextButtonEnable(false)
        if (event.target.name === 'projectName') {
            setProjectName(event.target.value)
        }
        if (event.target.name === 'description') {
            setDescription(event.target.value)
        }
    }
    return (
        <div>

            <div className="modalBodyContainer">
                <h3 style={{ fontSize: "16px", fontWeight: 500 }}>Add Project</h3><br />
                <form className="about-feedback">
                    {apiProgress.ready && <Alert message={message} type="success" closable />}
                    {apiProgress.error && <Alert message={apiProgress.error} type="error" closable />}
                    {apiProgress?.info && <Alert message={apiProgress.msg} type="info" closable />}
                    <div className="about-form-content">
                        <p style={{ color: "grey" }}>Name:</p>
                        <Input data-testid = 'paas-createProject-modal-projectName-input' placeholder="Project Name" name="projectName" value={projectName} onChange={handleValueChange} style={{ width: "100%" }}  disabled={nextButtonEnable}/>
                    </div>
                    <div className="about-form-content">
                        <p style={{ color: "grey" }}>Description:(1000 characters Limit)</p>
                        <TextArea  data-testid = 'paas-createProject-modal-description-input' placeholder="Brief description about the Project" name="description" value={description} onChange={handleValueChange} style={{ width: "100%" }} disabled={nextButtonEnable}/>
                    </div>
                </form>
            </div>
            <div style={{ height: "1px", background: "#e4dddd", marginTop: "72px" }}></div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "right", gap: "16px", marginRight: "0px", marginTop: "16px" }}>
                <Button data-testid = 'paas-createProject-modal-cancel-btn'
                    onClick={onCancel}
                >
                    {" "}
                    Cancel
                </Button>
                <Button data-testid = 'paas-createProject-modal-save-btn'
                    key="modalOkButton"
                    onClick={addProjectDetails}
                    disabled={!saveButton || !(projectName && description) || !(description.length<=1000)}
                >
                    {" "}
                    Save
                </Button>
                <Button data-testid = 'paas-createProject-modal-next-btn'
                    type="primary"
                    key="modalDismissButton"
                    disabled={!nextButtonEnable}
                    onClick={onNext}
                >
                    {" "}
                    Next
                </Button>
            </div>
        </div>
    );
};

export default ProjectDetails;
