import React, { useState } from 'react';
import { Tabs, Badge, StackingLayout, FlexLayout } from '@nutanix-ui/prism-reactjs';
import PendingTable from './PendingTable';

import './styles.css';

export default function TableTabs({recordData, loading}) {
    const [activeTab, setActiveTab] = useState(0);
    
    const data = [
        {
            title: <div className='TableTabContainer'>
                <p>Review Pending</p>
                <Badge color="gray" count={recordData?.pending_list?.length || '...'} />
            </div>,
            key: 1
        },
        {
            title: <div className='TableTabContainer'>
                <p>Hold</p>
                <Badge color="gray" count={recordData?.hold_list?.length || '...'} />
            </div>,
            key: 2
        },
        {
            title: <div className='TableTabContainer'>
                <p>Rejected</p>
                <Badge color="gray" count={recordData?.rejected_list?.length || '...'} />
            </div>,
            key: 3
        },
        {
            title: <div className='TableTabContainer'>
                <p>Approved</p>
                <Badge color="gray" count={recordData?.approved_list?.length || '...'} />
            </div>,
            key: 4
        },
        {
            title: <div className='TableTabContainer'>
                <p>All</p>
                <Badge color="gray" count={recordData?.all_list?.length || '...'} />
            </div>,
            key: 5
        }
    ];

    const handleTabClick = (tabKey) => {
        setActiveTab(prevState => tabKey-1);
    }
    const renderTabContent = () => {
        if(activeTab === 0) {
            return <PendingTable recordData={recordData?.pending_list} loading={loading} type={"PENDING"}/>
        } else if(activeTab === 1) {
            return <PendingTable recordData={recordData?.hold_list} loading={loading} type={"HOLDING"}/>
        } else if(activeTab === 2) {
            return <PendingTable recordData={recordData?.rejected_list} loading={loading} type={"REJECTED"}/>
        } else if(activeTab === 3) {
            return <PendingTable recordData={recordData?.approved_list} loading={loading} type={"APPROVED"}/>
        }
        return <PendingTable recordData={recordData?.all_list} loading={loading} type={"ALL"}/>
    }

    return <StackingLayout data-test-id="default" className='dunsTableContainer'>
        <Tabs
            data={data}
            onTabClick={handleTabClick}
            defaultActiveKey={1}
            data-test-id="default-active"
        />
        <FlexLayout
            padding="20px"
            id={`${''}-${activeTab}`}
            role="tabpanel"
            aria-labelledby={activeTab}
            tabIndex={0}
        >
            {renderTabContent()}
        </FlexLayout>
    </StackingLayout>


}