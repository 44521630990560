const _ = require('lodash')

const attribute_list = [
	{
		"attribute_name": "addrline1",
		"description": "Address Line 1"
	},
	{
		"attribute_name": "addrline2",
		"description": "Address Line 2"
	},
	{
		"attribute_name": "assignmodel_ctrygrp_empcount",
		"description": "Country Group Emp Count - Assignment Model"
	},
	{
		"attribute_name": "assignmodel_du_empcount",
		"description": "DU Emp Count - Assignment Model"
	},
	{
		"attribute_name": "assignmodel_gu_empcount",
		"description": "GU Emp Count - Assignment Model"
	},
	{
		"attribute_name": "assignmodel_linkdcomp_empcount",
		"description": "Linked Comp Emp Count - Assignment Model"
	},
	{
		"attribute_name": "assignmodel_phyloc_empcount",
		"description": "Physical Loc Emp Count - Assignment Model"
	},
	{
		"attribute_name": "city",
		"description": "City"
	},
	{
		"attribute_name": "ctrycode",
		"description": "Country Code"
	},
	{
		"attribute_name": "du_duns",
		"description": "DU DUNS"
	},
	{
		"attribute_name": "du_empcount",
		"description": "DU Emp Count"
	},
	{
		"attribute_name": "du_fin_yearlyrevenue",
		"description": "DU Annual Sales Volume"
	},
	{
		"attribute_name": "du_primaryname",
		"description": "DU Name"
	},
	{
		"attribute_name": "dunscontrolstatus_operatingstatus",
		"description": "Operating Status"
	},
	{
		"attribute_name": "familytreerolesplayed_branchdiv_code",
		"description": "Family Tree Branch/Division Code"
	},
	{
		"attribute_name": "familytreerolesplayed_branchdiv_desc",
		"description": "Family Tree Branch/Division Description"
	},
	{
		"attribute_name": "familytreerolesplayed_desc",
		"description": "Family Tree Description"
	},
	{
		"attribute_name": "familytreerolesplayed_du_code",
		"description": "Family Tree DU Code"
	},
	{
		"attribute_name": "familytreerolesplayed_du_desc",
		"description": "Family Tree DU Description"
	},
	{
		"attribute_name": "familytreerolesplayed_gu_code",
		"description": "Family Tree GU Code"
	},
	{
		"attribute_name": "familytreerolesplayed_gu_desc",
		"description": "Family Tree GU Description"
	},
	{
		"attribute_name": "familytreerolesplayed_parent_hq_code",
		"description": "Family Tree Parent HQ Code"
	},
	{
		"attribute_name": "familytreerolesplayed_parent_hq_desc",
		"description": "Family Tree Parent HQ Description"
	},
	{
		"attribute_name": "familytreerolesplayed_subs_code",
		"description": "Family Tree Subsidiary Code"
	},
	{
		"attribute_name": "familytreerolesplayed_subs_desc",
		"description": "Family Tree Subsidiary Description"
	},
	{
		"attribute_name": "financials_yearlyrevenue",
		"description": "Annual Sales Volume"
	},
	{
		"attribute_name": "gu_duns",
		"description": "GU DUNS"
	},
	{
		"attribute_name": "gu_empcount",
		"description": "GU Emp Count"
	},
	{
		"attribute_name": "gu_primaryname",
		"description": "GU Name"
	},
	{
		"attribute_name": "hq_duns",
		"description": "HQ DUNS"
	},
	{
		"attribute_name": "hq_primaryname",
		"description": "HQ Name"
	},
	{
		"attribute_name": "industrycode2_code",
		"description": "Industry Code Priority 2"
	},
	{
		"attribute_name": "industrycode2_desc",
		"description": "Industry Description Priority 2"
	},
	{
		"attribute_name": "industrycode_code",
		"description": "Industry Code"
	},
	{
		"attribute_name": "industrycode_desc",
		"description": "Industry Description"
	},
	{
		"attribute_name": "isfortune1000listed",
		"description": "Fortune 1000 Rank"
	},
	{
		"attribute_name": "isstandalone",
		"description": "Standalone Indicator"
	},
	{
		"attribute_name": "legalform_region",
		"description": "Legal Form Registration Region"
	},
	{
		"attribute_name": "locationownershipdesc",
		"description": "Location Ownership Description"
	},
	{
		"attribute_name": "locationownershipdnbcode",
		"description": "Location Ownership Code"
	},
	{
		"attribute_name": "num_emp_figuresdate",
		"description": "Emp Count Figures Date"
	},
	{
		"attribute_name": "num_emp_reliabilitydesc",
		"description": "Emp Count Reliability Description"
	},
	{
		"attribute_name": "num_emp_reliabilitydnbcode",
		"description": "Emp Count Reliability Code"
	},
	{
		"attribute_name": "num_emp_scopedesc",
		"description": "Emp Count Scope Description"
	},
	{
		"attribute_name": "num_emp_scopednbcode",
		"description": "Emp Count Scope D&B Code"
	},
	{
		"attribute_name": "num_emp_value",
		"description": "Total Emp Count"
	},
	{
		"attribute_name": "parent_duns",
		"description": "Parent DUNS"
	},
	{
		"attribute_name": "parent_primaryname",
		"description": "Parent Name"
	},
	{
		"attribute_name": "postalcode",
		"description": "Postal Code"
	},
	{
		"attribute_name": "primaryname",
		"description": "Name"
	},
	{
		"attribute_name": "regionname",
		"description": "Region Name"
	},
	{
		"attribute_name": "socioeconomic_isminorityowned",
		"description": "Minority Owned Indicator"
	},
	{
		"attribute_name": "stockexchanges_tickername",
		"description": "Stock Exchange Ticker Name"
	},
	{
		"attribute_name": "telephone_isdcode",
		"description": "Telephone ISD Code"
	},
	{
		"attribute_name": "telephone_number",
		"description": "Telephone Number"
	},
	{
		"attribute_name": "tradestylename",
		"description": "Trade Style Name"
	},
	{
		"attribute_name": "websitedomain",
		"description": "Website Domain"
	},
	{
		"attribute_name": "websiteurl",
		"description": "Website URL"
	}
]

const getCompleteAttributeList = (data) => {
	const res = [];
	let i = 0;
	attribute_list.forEach(ob => {
		const found = _.some(data, (item) => _.includes(item.attribute_name, ob.attribute_name));
		res.push({
			id: i++,
			label: ob.description,
			value: ob.attribute_name,
			disabled: !found
		})
	});
	return res;
}

module.exports = {
	getCompleteAttributeList
}