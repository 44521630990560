import React, { useEffect, useState } from "react";

import moment from 'moment';

import './styles.css';

import {
  Button,
} from '@nutanix-ui/prism-reactjs';
import DateRangeFilter from "./components/filters/DateRangeFilter";
import EventTypeFilter from "./components/filters/EventTypeFilter";
import ViewAttributesDGO from "./ViewAttributesDGO";
import RegionFilter from "./components/filters/RegionFilter";
import DunSearchBar from "./DunSeachBar";


export default function DGO_Filter_Pane(props) {


  // event type filter values
  const [eventTypeValue, setEventTypeValue] = useState([{
    id: 0,
    label: 'ALL',
    value: 'all'
  }]);
  const [eventTypeList, setEventTypeList] = useState([]);

  // account type filter values
  const [accountTypeValue, setAccountTypeValue] = useState([{
    id: 0,
    label: 'ALL',
    value: 'all'
  }]);
  const [accountTypeList, setAccountTypeList] = useState([])

  // theatre filter values
  const [theatreValue, setTheatreValue] = useState([{
    id: 0,
    label: 'ALL',
    value: 'all'
  }]);
  const [theatreList, setTheatreList] = useState([]);

  // region filter values
  const [regionValue, setRegionValue] = useState([{
    id: 0,
    label: 'ALL',
    value: 'all'
  }]);
  const [regionList, setRegionList] = useState([]);

  // sub-region values 
  const [subRegionValue, setSubRegionValue] = useState([{
    id: 0,
    label: 'ALL',
    value: 'all'
  }]);

  const [subRegionList, setSubRegionList] = useState([]);

  // date filter values
  let date  = moment().format('YYYY-MM-DD');
  const [endDate, setEndDate] = useState(date);

  date = moment().subtract(12, 'months').format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(date);

  //loading 
  const [loading, setLoading] = useState(true);

  //attributes 
  const [attributesList, setAttributesList] = useState([]);

  const [searchDunsNum, setSearchDunsNum] = useState(null);


  // functions 
  const createSelectedValueObj = (data) => {
    const obj = [];
    let i = 0;
    data?.forEach(ob => {
      obj.push({
        id: i++,
        label: ob?.toUpperCase(),
        value: ob
      });
    })
    return obj;
  }

  const createOptionListObj = (filterName, data, value) => {
    let i = 0;
    const list = [];
    value?.forEach(ob => {
      list?.push({
        id: i++,
        label: ob?.toUpperCase(),
        value: ob
      });
    });

    // if (value.length === 0) {
    //   if (filterName === 'sub_region') {
    //     setSubRegionValue(prevState => {
    //       return [
    //         {
    //           id: i++,
    //           label: data[0]?.toUpperCase(),
    //           value: data[0]
    //         }
    //       ]
    //     })
    //   } else if (filterName === 'theatre') {
    //     setTheatreValue(prevState => {
    //       return [
    //         {
    //           id: i++,
    //           label: data[0]?.toUpperCase(),
    //           value: data[0]
    //         }
    //       ]
    //     })
    //   } else if (filterName === 'region') {
    //     setRegionValue(prevState => {
    //       return [
    //         {
    //           id: i++,
    //           label: data[0]?.toUpperCase(),
    //           value: data[0]
    //         }
    //       ]
    //     })
    //   } else if (filterName === 'account_type') {
    //     setAccountTypeValue(prevState => {
    //       return [
    //         {
    //           id: i++,
    //           label: data[0]?.toUpperCase(),
    //           value: data[0]
    //         }
    //       ]
    //     })
    //   } else if (filterName === 'event_type') {
    //     setEventTypeValue(prevState => {
    //       return [
    //         {
    //           id: i++,
    //           label: data[0]?.toUpperCase(),
    //           value: data[0]
    //         }
    //       ]
    //     })
    //   }
    // }

    data?.forEach(ob => {
      let found = false;
      list?.forEach(o => {
        if (o.value === ob) {
          found = true
        }
      });
      if (!found) {
        list.push({
          id: i++,
          label: ob.toUpperCase(),
          value: ob
        })
      }
    });

    return list;
  }

  const handleApplyFilter = () => {
    const obj = {
      startDate, endDate, eventTypeValue, accountTypeValue, theatreValue, regionValue, subRegionValue, attributesList, searchDunsNum
    }
    props.setFiltersUpdatedData(prevState => obj);
  }

  useEffect(() => {
    const { event_type, account_type, theater, region, sub_region, date_filtes } = props.data;

    if (Object.keys(props.data).length > 0) {
      setEventTypeValue(prevState => createSelectedValueObj(event_type.default_value));
      setEventTypeList(prevState => createOptionListObj('event_type', event_type.option_list, event_type.default_value));
      setAccountTypeValue(prevState => createSelectedValueObj(account_type.default_value))
      setAccountTypeList(prevState => createOptionListObj('account_type', account_type.option_list, account_type.default_value));
      setRegionValue(prevState => createSelectedValueObj(region.default_value))
      setRegionList(prevState => createOptionListObj('region', region.option_list, region.default_value));
      setTheatreValue(prevState => createSelectedValueObj(theater.default_value));
      setTheatreList(prevState => createOptionListObj('theatre', theater.option_list, theater.default_value));
      setSubRegionValue(prevState => createSelectedValueObj(sub_region.default_value));
      setSubRegionList(prevState => createOptionListObj('sub_region', sub_region.option_list, sub_region.default_value))
      // setStartDate(prevState => date_filtes?.default_value?.startDate);
      // setEndDate(prevState => date_filtes?.default_value?.endDate);
      setLoading(prevState => false);
    }
  }, [props.data]);

  return <div className="dgo_filter_pane_main_container">
    <div className="dgo_filter_pane_container">
      <div className="filterPaneHeader">
        <h3>Filters</h3>
      </div>
      <div className="dgo_filter_pane_content">
        <DateRangeFilter
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <EventTypeFilter
          loading={loading}
          label="Event Type"
          defaultValue={eventTypeValue}
          setdefaultValue={setEventTypeValue}
          optionList={eventTypeList}
        />

        <EventTypeFilter
          loading={loading}
          label="Account Type"
          defaultValue={accountTypeValue}
          setdefaultValue={setAccountTypeValue}
          optionList={accountTypeList}
        />

        <EventTypeFilter
          loading={loading}
          label="Theatre"
          defaultValue={theatreValue}
          setdefaultValue={setTheatreValue}
          optionList={theatreList}
        />

        <RegionFilter
          loading={loading}
          defaultValue={regionValue}
          setdefaultValue={setRegionValue}
          optionList={regionList}
          subRegionDefaultValue={subRegionValue}
          subRegionSetValue={setSubRegionValue}
        />

        <div className="filterBtnContainer">
          <div style={{ marginTop: '1.2rem' }}>
            <Button onClick={handleApplyFilter}>
              <span style={{ "fontSize": "14px", "lineHeight": "14px" }}>Apply Filters</span>
            </Button>
          </div>
          <div style={{ marginTop: '1.2rem' }}>
            <Button>
              <span style={{ "fontSize": "14px", "lineHeight": "14px" }}>Save Setting</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div className="secondLevelFilterPane">
      <DunSearchBar searchDunsNum={searchDunsNum} setSearchDunsNum={setSearchDunsNum} handleApplyFilter={handleApplyFilter}/>
      <ViewAttributesDGO handleApplyFilter={setAttributesList}  />
    </div>
  </div>
}
