/* eslint-disable react/no-did-mount-set-state */
/**
 * @file Manages the Auth HOC (Higher Order Component)
 * @description This file contains the implementation of an authorization HOC. 
 * The HOC wraps around a component and ensures that the user is authenticated 
 * before rendering the wrapped component. If the user is not authenticated, 
 * they are redirected to the login page.
 * @autor Abhishek Sharma
 */

// Lib
import React, { useContext } from "react";
import { withRouter } from "react-router-dom";

// components
import Loading from "../Components/Loader";  // Component to show loading spinner
import { SERVER_URL } from "../config/config";  // Configuration file containing server URL
import logger from "../config/logger";  // Logger utility for logging
import UserContext from "../contexts/UserContext/UserContext";  // Context for user state
import DGO from "../Components/DGO/DGO";
import { DGO_USERS_LIST } from "./dgoUsersList";

/**
 * Authorization - Authorization HOC
 * @param {function} WrappedComponent - Component to be wrapped
 * @returns {function} - Authorization HOC
 * @description This HOC ensures that the user is authenticated before rendering the wrapped component. 
 * If the user is not authenticated, it fetches the user data and sets the user state. If authentication 
 * fails, it redirects the user to the login page.
 */

const Authorization = (WrappedComponent) => {
    const WithAuthorization = (props) => {
        // Use the UserContext to get the user state and fetchUser function
        const { user, loading, fetchUser } = useContext(UserContext);
        // Effect to fetch the user data if not already fetched
        if (!user && !loading) {
            fetchUser();
        }

        // If the user data is still loading, show a loading spinner
        if (loading) {
            return <Loading />;
        }

        // If the user is not authenticated, redirect to the login page
        if (!user) {
            const loginUrl = `${SERVER_URL}/login`;
            logger.info("Redirecting to login");
            window.location.href = loginUrl;
            return null;  // Return null to prevent rendering anything else
        }

        if(props.location?.pathname !== "/dunsChanges" && DGO_USERS_LIST.includes(user)) {
            return <DGO {...props} user={user} />
        }
        // If the user is authenticated, render the wrapped component with the user prop
        return <WrappedComponent {...props} user={user} />;
    };

    return withRouter(WithAuthorization);  // Wrap the component with withRouter to have access to router props
};

export default Authorization;
