import React, { useState, useEffect } from "react";
import { Button } from "antd";
import axios from "axios";
import { apiResponseError } from "../../config/helper";
import "./NextGenEtl.css";
import { SERVER_URL } from "../../config/config";
import TeamMembersInput from "./TeamMembersInput";
import TeamTable from "./TeamTable";
import { useNextGenCreateProject } from '../../../src/hooks'
import teamMembers from './teamMembers.json'
import { Alert } from '@nutanix-ui/prism-reactjs';

const TeamDetails = (props) => {
    const { onCancel, onNext} = { ...props }
    // const [teamTableEnable, setTeamTableEnable] = useState(false)
    const [apiProgress, setApiProgress] = useState({ loading: false, ready: false, error: false })
    const [message, setMessage] = useState('')
    const [user, setUser] = useState({ permission: "Admin", users: [] })
    const [users, setUsers] = useState([])
    const [nextButtonEnable, setNextButtonEnable] = useState(false)
    const [saveButton, setSaveButton] = useState(false)
    const permissions = [{id: 1, label: "Admin", value: "Admin", rowProps: {'data-testid': 'Admin'}}, 
                        { id: 2, label: "Editor", value: "Editor" ,rowProps: {'data-testid': 'Editor'}}, 
                        {id: 3, label: "Viewer", value: "Viewer" ,rowProps: {'data-testid': 'Viewer'
                    }}]
    const { projectDetails } = useNextGenCreateProject()
const fetchProjectTeamMembers=async()=>{
    let team_input = {
        entity: "Project",
        id: projectDetails?.project_id || "",
      };
      const team_data = await axios.post(
        `${SERVER_URL}/listMembersModel`,
        team_input
      );
      let team_details_table = await team_data?.data?.data.map((e, index) => {
        return {
          key: index,
          name: e[0]
            .split("@")[0]
            .split(".")
            .map((e) => {
              return e.charAt(0).toUpperCase() + e.slice(1);
            })
            .join(" "),
          email: e[0],
          permission: e[1],
        };
      });
      if (team_details_table?.length > 0) {
        setNextButtonEnable(false);
      }
      setUsers(team_details_table);
}
     useEffect(() => {
        fetchProjectTeamMembers()// eslint-disable-next-line 
     }, []);
    const enableTeamTableView = () => {
        // setTeamTableEnable(true)
    if(user?.users.length>0 && user?.permission.length>0) {
        let offsetKey = users?.reduce((acc, curr) => {
            if (curr.key > acc) {
                acc = curr.key
                return acc
            }
            return acc
        }, 0)
        const userFlattened = user?.users?.reduce((acc1, curr1) => {
            offsetKey = offsetKey + 1
            acc1.push({ permission: user?.permission, name: curr1.split("@")[0]
            .split(".")
            .map((e) => {
            return e.charAt(0).toUpperCase() + e.slice(1);
            })
            .join(" "), key: offsetKey, email:curr1})
            return acc1
        }, [])
        setUser({ permission: "Admin", users: [] })
        setUsers(current => ([...current, ...userFlattened]))
    } else {
     setApiProgress({ loading: false, ready: false, error: false, info: true, msg:'Please provide all details' })
    }
    }
    const handleChange = (name, value) => {
        setSaveButton(true)
        setUser(current => ({ ...current, [name]: value }))
    }
    const addTeamMembers = async (e) => {
        try {

       setApiProgress({ loading: false, ready: false, error: false, info:true, msg:'Add Members Request has been Submitted' })

            let team_details = {
                entity: "Project",
                id: projectDetails.project_id,
                members: users.map(e => {
                    return (
                        {
                            member_id: e?.email || "",
                            member_role: e?.permission || "",
                            email: e?.email || ""
                        }
                    )
                })
            }
            const data = await axios.post(`${SERVER_URL}/addMembersModel`, team_details)
        if (Object.keys(data?.data?.invalid_emails).length===0){
            setMessage(data?.data?.msg)
            setNextButtonEnable(true)
            setSaveButton(false)
            setApiProgress({ loading: false, ready: true, error: false })
        } else {
            setApiProgress({ loading: false, ready: false, error: true, info:false, msg:'Please provide valid email' })
            fetchProjectTeamMembers()
        }

        } catch (e) {
            setApiProgress({ loading: false, ready: false, error: apiResponseError(e) })
        }
    }
    return (
        <>
            <div className="modalBodyContainer">
                <h3 style={{ fontSize: "16px", fontWeight: 500 }}>Share With</h3><br />
                {apiProgress?.ready && <><Alert message={message} type="success" closable /></>}
                {apiProgress?.error && <><Alert message={apiProgress?.msg} type="error" closable /></>}
                {apiProgress?.info && <Alert message={apiProgress?.msg} type="info" closable />}
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <div style={{ display: "flex", alignItems: "center", gap: 8, flex: 1 }}>
                        <TeamMembersInput data-testid = 'paas-createProject-addMember-roles-selection' options={permissions} width={"150px"} handleChange={(event) => handleChange("permission", event)} value={user["permission"]} />
                        <TeamMembersInput data-testid = 'paas-createProject-addMember-users-selection' options={teamMembers.teamMembers || []} mode={"tags"} style={{ flex: 1 }} onChange handleChange={(event) => handleChange("users", event)} value={user["users"]} />
                    </div>

                    <div className="about-form-content">
                        <Button type="primary" onClick={enableTeamTableView} data-testid = 'paas-createProject-addMember-add-btn' >Add</Button>
                    </div>
                </div>
                <div style={{ marginTop: "18px" }}>
                    {users?.length > 0 && <TeamTable users={users} setUsers={setUsers} setSaveButton={setSaveButton} />}
                </div>
            </div>
            <div style={{ height: "1px", background: "#e4dddd", marginTop: "72px" }}></div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "20px",float:'right' }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "right", gap: "16px", marginRight: "0px", marginTop: "16px" }}>
                    <Button data-testid = 'paas-createProject-addMember-cancel-btn'
                        onClick={onCancel}
                    >
                        {" "}
                        Cancel
                    </Button>
                    <Button data-testid = 'paas-createProject-addMember-save-btn'
                        key="modalOkButton"
                        onClick={addTeamMembers}
                        disabled={!saveButton}
                    >
                        {" "}
                        Save
                    </Button>
                    <Button data-testid = 'paas-createProject-addMember-next-btn'
                        type="primary"
                        key="modalDismissButton"
                        disabled={!nextButtonEnable}
                        onClick={onNext}
                    >
                        {" "}
                        Next
                    </Button>
                </div>
            </div>
        </>
    );
};

export default TeamDetails;
