import React, { useEffect, useState } from "react";
import { Button, Checkbox, CloseIcon, FlexItem, FlexLayout, FormItemInput, FormItemSelect, Loader, PlusIcon } from "@nutanix-ui/prism-reactjs";

import './styles.css';

export default function AddColumnsForm(props) {

    const [columnData, setColumnData] = useState(props.columns || []);

    const [selectedRow, setSelectedRow] = useState({
        key: 0,
        label: 'Select Data Type',
        value: 'select'
    });

    const [columnNameInput, setColumnNameInput] = useState('');

    const [columnDataTypeInput, setColumnDataTypeInput] = useState('')

    const [columnDataTypeValueInput, setColumnDataTypeValueInput] = useState('')

    const [isValidate, setIsValidate] = useState(false);

    useEffect(() => {
        setColumnData(props.columns)
    }, [props])

    const handleAdd = () => {
        const obj = {
            key: Math.random().toString(36).slice(2, 7),
            columnName: columnNameInput,
            dataType: columnDataTypeInput?.value,
            dataTypeValue: columnDataTypeValueInput,
            isValidate: isValidate
        }
        setColumnData(prevState => [...prevState, obj]);
        props.setColumnData(prevState => [...prevState, obj]);
        setColumnNameInput('');
        setColumnDataTypeInput('');
        setColumnDataTypeValueInput('');
        setIsValidate(false);
        setSelectedRow({
            key: 0,
            label: 'Select Data Type',
            value: 'select'
        });
    }

    const handleRemove = (row) => {
        setColumnData(columnData.filter((column, i) => column.key !== row.key));
        props.setColumnData(columnData.filter((column, i) => column.key !== row.key));
    }

    const handleValidationCheck = () => {
        setIsValidate(prevState => !prevState);
    }


    const renderColumn = (colData, isLast) => {
        let label = "Length"
        const data = [
            {
                key: 0,
                label: 'Select Data Type',
                value: 'select'
            },
            {
                key: 1,
                label: 'Small Integer',
                value: 'smallint'
            },
            {
                key: 2,
                label: 'Integer',
                value: 'integer'
            },
            {
                key: 3,
                label: 'Big Integer',
                value: 'bigint'
            },
            {
                key: 4,
                label: 'Small Serial',
                value: 'smallserial'
            },
            {
                key: 5,
                label: 'Serial',
                value: 'serial'
            },
            {
                key: 6,
                label: 'Big Serial',
                value: 'bigserial'
            },
            {
                key: 7,
                label: 'Text',
                value: 'text'
            },
            {
                key: 8,
                label: 'JSON',
                value: 'json'
            },
            {
                key: 9,
                label: 'Real',
                value: 'real'
            },
            {
                key: 10,
                label: 'Double Precision',
                value: 'double precision'
            },
            {
                key: 11,
                label: 'Decimal',
                value: 'decimal'
            },
            {
                key: 12,
                label: 'Varchar',
                value: 'varchar'
            },
            {
                key: 13,
                label: 'Date',
                value: 'date'
            },
            {
                key: 14,
                label: 'Timestamp',
                value: 'timestamp'
            }
        ];

        if (colData?.dataType?.label === "VARCHAR") {
            label = "Length";
        }

        const handleColumnChange = (e) => {
            setColumnNameInput(e.target.value.toLowerCase());
        }

        if (isLast) {
            return <FlexItem style={{ justifyContent: 'center' }}>
                <FlexLayout flexDirection="row" alignItems="center" style={{ padding: '1rem 2rem 1rem', background: '#F8F9FA' }}>
                    <FlexItem style={{ width: '30%' }}>
                        <FormItemInput className="textField" placeholder="Type Column Name" label="Column Name" width='100%'
                            data-testid="columnNameInput" wrapperProps={{ 'data-test-id': 'default' }} onChange={handleColumnChange} value={columnNameInput} />
                    </FlexItem>
                    <FlexItem style={{ width: '30%' }} data-testid="default">
                        <div>
                            <FormItemSelect
                                className="textField"
                                rowsData={data}
                                label="Data Type"
                                width='100%'
                                selectedRow={selectedRow}
                                onSelectedChange={(item) => {
                                    setSelectedRow(prevState => item);
                                    setColumnDataTypeInput(prevState => item);
                                }}
                            />
                        </div>
                    </FlexItem>
                    {renderDataTypeValue()}
                    <FlexItem>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '1rem' }}>
                            <label style={{ color: '#627386', fontSize: '12px', fontWeight: 500 }}>Validate</label>
                            <Checkbox
                                id="validation_required"
                                onChange={handleValidationCheck}
                                checked={isValidate} />
                        </div>
                    </FlexItem>
                    <FlexItem>
                        <Button style={{ marginTop: '20px', padding: '0px' }} onClick={handleAdd} data-testid="addButton"> <PlusIcon /> </Button>
                    </FlexItem>
                </FlexLayout >
            </FlexItem >
        }
        return <FlexItem style={{ justifyContent: 'center' }}>
            <FlexLayout flexDirection="row" alignItems="center" style={{ padding: '1rem 2rem 1rem', background: '#F8F9FA' }}>
                <FlexItem style={{ width: '40%' }}>
                    <FormItemInput className="textField" label="Column Name" width='100%'
                        wrapperProps={{ 'data-test-id': 'default' }} disabled value={colData.columnName} data-testid='default' />
                </FlexItem>
                <FlexItem style={{ width: '40%' }} data-testid="default">
                    <FormItemInput className="textField" label="Data Type" width='100%'
                        wrapperProps={{ 'data-test-id': 'default' }} disabled value={colData.dataType} />
                </FlexItem>
                <FlexItem style={{ width: '40%' }} data-testid="default">
                    <FormItemInput className="textField" label={label} width='100%'
                        wrapperProps={{ 'data-test-id': 'default' }} disabled value={colData.dataTypeValue} />
                </FlexItem>
                <FlexItem>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '1rem' }}>
                        <label style={{ color: '#627386', fontSize: '12px', fontWeight: 500 }}>Validate</label>
                        <Checkbox
                            id="validation_required"
                            disabled
                            checked={colData.isValidate} />
                    </div>
                </FlexItem>
               {props.updateAllowed && <FlexItem>
                    <Button style={{ marginTop: '20px', background: '#586678', padding: '3px' }} onClick={(e) => handleRemove(colData)} data-testid="removeButton"> <CloseIcon size="small" /> </Button>
                </FlexItem>}
            </FlexLayout>
        </FlexItem>
    }

    const renderDataTypeValue = () => {
        let label = "Length"
        let placeHolder = "Please add length here";
        let disabled = false
        if (selectedRow.value !== "decimal" && selectedRow.value !== "varchar") {
            placeHolder = "";
            disabled = true
        } else if (selectedRow.value === "decimal") {
            placeHolder = "10,2";
            label = "Precision";
        } else if (selectedRow.value === "varchar") {
            placeHolder = "100";
            label = "Length";
        }
        return <FlexItem>
            <FormItemInput className="textField" placeholder={placeHolder} label={label} width='100%'
                disabled={disabled} data-testid="dataTypeInput" wrapperProps={{ 'data-test-id': 'default' }} value={columnDataTypeValueInput} onChange={(e) => setColumnDataTypeValueInput(prevState => e.target.value)} />
        </FlexItem>
    }

    const renderAddedColumns = () => {
        return columnData.map((ob, idx) => {
            return renderColumn(ob, false);
        })
    }

    if (props.loading) {
        return <div className="addColumnFormContainerLoader">
            <Loader />
        </div>
    }
    // if (!props.updateAllowed) {
    //     return <div className="addColumnFormContainerLoader">
    //         <h3>Modification to this template is prohibited</h3>
    //     </div>
    // }
    return <div className="addColumnFormContainer">
        <FlexLayout
            flexDirection="column"
        >
            <FlexItem><h3>Add Template Details</h3></FlexItem>
            <FlexItem>{renderAddedColumns()}
                {renderColumn({}, true)}</FlexItem>
        </FlexLayout>
    </div>
}