import { Button, MagGlassIcon, TextInput } from "@nutanix-ui/prism-reactjs";
import React from "react";
import './styles.css';

export default function DunSearchBar({searchDunsNum, setSearchDunsNum, handleApplyFilter}) {

    const handleTextChange = (e) => {
        setSearchDunsNum(prevState => e.target.value)
    }

    const handleClick = () => {
        handleApplyFilter();
    }
    return <div className="dgoSearchBar">
        <TextInput
            name="text-input-normal"
            data-test-id="default"
            placeholder="Search by Duns number"
            value={searchDunsNum}
            onChange={handleTextChange}
            style={{background: 'white'}}
        />
        <Button onClick={handleClick}>
            <MagGlassIcon  size="large" />
        </Button>
    </div>
}
