import React, { useEffect } from "react";
import {
  Tabs,
  Badge,
  StackingLayout,
  FlexLayout,
} from "@nutanix-ui/prism-reactjs";
import PendingTable from "./PendingTable";
import "./styles.css";
import { useDGOContext } from "./context/dgoContext";

export default function TableTabs({ recordData, loading }) {
  const {
    pagination,
    activeTab,
    setPaginationForTab,
    setActiveTab,
  } = useDGOContext();

  useEffect(() => {
    const tabMapping = {
      0: "pending",
      1: "holding",
      2: "rejected",
      3: "approved",
      4: "all",
    };

    const listMapping = {
      pending: recordData?.pending_list,
      holding: recordData?.hold_list,
      rejected: recordData?.rejected_list,
      approved: recordData?.approved_list,
      all: recordData?.all_list,
    };

    const activeTabKey = tabMapping[activeTab];
    const activeList = listMapping[activeTabKey];

    if (activeTabKey && activeList?.length) {
      setPaginationForTab(activeTabKey, {
        total: activeList.length || 1,
        currentPage: pagination[activeTabKey]?.currentPage || 1,
        pageSize: pagination[activeTabKey]?.pageSize || 10,
      });
    }
  }, [activeTab]); // Keep dependencies minimal

  const data = [
    { title: <TabTitle text="Review Pending" count={recordData?.pending_list?.length} />, key: 0 },
    { title: <TabTitle text="Hold" count={recordData?.hold_list?.length} />, key: 1 },
    { title: <TabTitle text="Rejected" count={recordData?.rejected_list?.length} />, key: 2 },
    { title: <TabTitle text="Approved" count={recordData?.approved_list?.length} />, key: 3 },
    { title: <TabTitle text="All" count={recordData?.all_list?.length} />, key: 4 },
  ];

  const handleTabClick = (tabKey) => {
    if (tabKey !== activeTab) {
      setActiveTab(tabKey); // Update only if necessary
    }
  };

  const renderTabContent = () => {
    const tabMapping = {
      0: { type: "PENDING", list: recordData?.pending_list, key: "pending" },
      1: { type: "HOLDING", list: recordData?.hold_list, key: "holding" },
      2: { type: "REJECTED", list: recordData?.rejected_list, key: "rejected" },
      3: { type: "APPROVED", list: recordData?.approved_list, key: "approved" },
      4: { type: "ALL", list: recordData?.all_list, key: "all" },
    };

    const activeTabData = tabMapping[activeTab];
    if (activeTabData) {
      return (
        <PendingTable
          recordData={activeTabData.list}
          loading={loading}
          type={activeTabData.type}
          pagination={pagination[activeTabData.key]}
          handlePaginationChange={(newPage) =>
            setPaginationForTab(activeTabData.key, newPage)
          }
        />
      );
    }

    return null;
  };

  return (
    <StackingLayout data-test-id="default" className="dunsTableContainer">
      <Tabs
        data={data}
        onTabClick={handleTabClick}
        activeKey={activeTab} // Control active tab explicitly
        data-test-id="controlled-active"
      />
      <FlexLayout
        padding="20px"
        id={`tabpanel-${activeTab}`}
        role="tabpanel"
        aria-labelledby={activeTab}
        tabIndex={0}
      >
        {renderTabContent()}
      </FlexLayout>
    </StackingLayout>
  );
}

function TabTitle({ text, count }) {
  return (
    <div className="TableTabContainer">
      <p>{text}</p>
      <Badge color="gray" count={count || "..."} />
    </div>
  );
}
