import React, { useEffect } from "react";

import {
    FormItemProvider,
    MultiSelect,
} from '@nutanix-ui/prism-reactjs';

import '../../styles.css';

const SelectWithLabel = FormItemProvider(MultiSelect);

export default function EventTypeFilter({ defaultValue, label, loading, optionList, setdefaultValue }) {

    const setEventTypeValue = (rows) => {
        setdefaultValue(prevState => rows);
    }

    useEffect(() => {
    }, [defaultValue]);

    const props = {
        rowsData: optionList,
        label: label,
        width: '4rem',
        errorType: 'critical',
    };

    return <div className='dgo_filter'>
        <SelectWithLabel
            data-test-id="overflow-tag-ellipsis"
            selectedRows={defaultValue || []}
            loading={loading}
            onSelectedChange={setEventTypeValue}
            overflowLimit={4}
            overflowTagVariant={MultiSelect.OverflowTagVariant.ELLIPSIS}
            {...props}
        />
    </div>

}