import React from "react";
import { Badge, LocalTable } from '@nutanix-ui/prism-reactjs';

import '../../styles.css';

export default function OverviewTable(props) {
    const columns = [
        {
            title: 'ID',
            key: 'id'
        },
        {
            title: 'Duns',
            key: 'duns'
        },
        {
            title: 'Date',
            key: 'date'
        },
        {
            title: 'SFDC ID',
            key: 'sfdc_id',
            render: (value, object, index) => <p className='sfdc_id_columns'>{object.sfdc_id}</p>
        },
        {
            title: 'Account Type',
            key: 'account_type'
        },
        {
            title: 'SFDC Account Name',
            key: 'sfdc_account_name'
        },
        {
            title: 'Theatre',
            key: 'theatre'
        },
        {
            title: 'Region',
            key: 'region'
        },
        {
            title: 'Sub Region',
            key: 'sub_region'
        },
        {
            title: 'Event',
            key: 'event_type',
            render: (value, object, index) => {
                if (object.event_type === 'DELETE') {
                    return <div className='eventTypeColumn'>
                        <Badge color="red" text={object.event_type} />
                    </div>
                }
                else if (object.event_type === 'REVIEWED') {
                    return <div className='eventTypeColumn'>
                        <Badge color="green" text={object.event_type} />
                    </div>
                } else if (object.event_type === 'TRANSFER') {
                    return <div className='eventTypeColumn'>
                        <Badge color="blue" text={object.event_type} />
                    </div>
                } else if (object.event_type === 'UNDER REVIEW') {
                    return <div className='eventTypeColumn'>
                        <Badge color="yellow" text={object.event_type} />
                    </div>
                }
                return <div className='eventTypeColumn'>
                    <Badge color="gray" text={object.event_type} />
                </div>
            }
        },
        {
            title: 'Changes',
            key: 'changes',
            render: (value, object, index) => {
                return <div className='tableActionColumn'>
                    <Badge color="blue" count={object.changes} />
                </div>
            }
        }];

    const topSection = {
        title: 'Overview',
    };

    return <div className="overviewChangesContainer">
        <LocalTable
            columns={columns}
            dataSource={props.data}
            topSection={topSection}
        />
    </div>
}