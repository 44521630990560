import React, { useEffect, useState } from "react";
import GraphDGO from "./GraphDGO";
import { Loader } from '@nutanix-ui/prism-reactjs'

import './styles.css';
import { getStats } from "../../Service/dgo";


export default function DGO_Statistics() {
    const [statsData, setStatsData] = useState({
        pending: 0,
        pendingGraph: [],
        approved: 0,
        approvedGraph: [],
        rejected: 0,
        rejectedGraph: [],
        hold: 0,
        holdGraph: [],
        total_reject: 0,
        total_rejectGraph: [],
        total_accepted: 0,
        total_acceptedGraph: [],
        total_pending: 0,
        total_pendingGraph: [],
        total_hold: 0,
        total_holdGraph: [],
    });

    const [statLoading, SetStatLoading] = useState(false);

    useEffect(() => {
        SetStatLoading(prevState => true);
        getStats()
            .then(resp => {
                setStatsData(prevState => resp);
                SetStatLoading(prevState => false);
            })
            .catch(err => {
                const errorData = {
                    pending: "N.A",
                    pendingGraph: [],
                    approved: "N.A",
                    approvedGraph: [],
                    rejected: "N.A",
                    rejectedGraph: [],
                    hold: "N.A",
                    holdGraph: [],
                    total_reject: "N.A",
                    total_rejectGraph: [],
                    total_accepted: "N.A",
                    total_acceptedGraph: [],
                    total_pending: "N.A",
                    total_pendingGraph: [],
                    total_hold: "N.A",
                    total_holdGraph: [],
                }
                setStatsData(prevState => errorData);
                SetStatLoading(prevState => false);
            })
    }, []);

    return <div className="dgo_stats_container">
        <div className="current_stat_container">
            <label className="stat_container_title">Current Month Change Analytics</label>
            <StatContainerCurrent
                data={statsData}
                loading={statLoading}
            />
        </div>
        <div className="total_stat_container">
            <label className="stat_container_title">Total Overall Change Analytics</label>
            <StatContainerTotal
                data={statsData}
                loading={statLoading}
            />
        </div>
    </div>
}

function StatContainerCurrent({data, loading}) {

    const {pending, approved, rejected, hold , pendingGraph, approvedGraph, rejectedGraph, holdGraph} = data;

    useEffect(() => {

    }, [data]);

    if (loading) return <div className="current_stat_content" style={{ "paddingTop": "2rem", "paddingBottom": "2rem" }}>
        <Loader loading={loading} />
    </div>
    return <div className="current_stat_content">
        <StatBox label="Pending" count={pending} graph={pendingGraph} color="#FFBC0B" />
        <StatBox label="Hold" count={hold} color="#F0955C" graph={holdGraph} />
        <StatBox label="Approved" count={approved} color="#36D068" graph={approvedGraph} />
        <StatBox label="Rejected" count={rejected} color="#E4677C" graph={rejectedGraph} />
    </div>
}

function StatContainerTotal({data, loading}) {

    const {total_accepted, total_acceptedGraph, total_hold, total_holdGraph , total_pending, total_pendingGraph, total_reject, total_rejectGraph} = data;

    useEffect(() => {

    }, [data]);

    if (loading) return <div className="current_stat_content" style={{ "paddingTop": "2rem", "paddingBottom": "2rem" }}>
        <Loader loading={loading} />
    </div>

    return <div className="total_stat_content">
        <StatBox label="Pending" count={total_pending} graph={total_pendingGraph} color="#FFBC0B" />
        <StatBox label="Hold" count={total_hold} color="#F0955C" graph={total_holdGraph} />
        <StatBox label="Approved" count={total_accepted} color="#36D068" graph={total_acceptedGraph} />
        <StatBox label="Rejected" count={total_reject} color="#E4677C" graph={total_rejectGraph} />
    </div>
}

function StatBox({ label, count, color, graph }) {

    useEffect(() => {
    }, [count]);

    return <div className="stat_box_container">
        <div className="stat_box_content">
            <h2>{count}</h2>
            <label className="stat_title">{label}</label>
        </div>
        <div className="graphLineDGOContainer">
            <GraphDGO data={graph} color={color} />
        </div>
    </div>
}

