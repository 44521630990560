import { Button, Checkbox, FlexItem, FlexLayout, LocalTable, RefreshIcon, VerticalSeparator } from "@nutanix-ui/prism-reactjs";
import React, { useEffect, useState } from "react";
import { getDunsChanges, putActionAll } from "../../../../Service/dgo";
import AllActionModal from "./AllActionModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SelectedActionModal from "./SelectedActionModal";
import { useDGOContext } from "../../context/dgoContext";

export default function DunsAuditTable({ duns, batch_id, listType }) {
      const {
        pagination,
        setPaginationForTab,
      } = useDGOContext();
    const history = useHistory();
    const [viewChangesData, setViewChangesData] = useState([]);
    const [newChange, setNewChange] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alertRejectModalVisible, setAlertRejectModalVisibile] = useState(false);
    const [alertApproveModalVisible, setAlertApproveModalVisibile] = useState(false);
    const [alertHoldModalVisible, setAlertHoldModalVisibile] = useState(false);
    const [actionReason, setActionReason] = useState('');
    const [isSelectedModalVisible, setIsSelectedModalVisible] = useState(false);
    const [approvedList, setApprovedList] = useState([])
    const [holdList, setHoldList] = useState([])
    const [rejectedList, setRejectedList] = useState([])
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if(listType === 'APPROVED' || listType === 'HOLDING' || listType === 'REJECTED') {
            setDisabled(prevState => true);
        } else {
            setDisabled(prevState => false);
        }
    }, [listType]);

    const columns = [
        {
            title: 'Attribute Name',
            key: 'attribute_name'
        },
        {
            title: 'Previous',
            key: 'previous',
            render: (value, object, index) => <p className='sfdc_id_columns'>{object.previous}</p>
        },
        {
            title: 'Current',
            key: 'current',
            render: (value, object, index) => <p className='sfdc_id_columns'>{object.current}</p>
        },
        {
            title: <FlexLayout>
                <FlexItem>
                    Hold
                </FlexItem>
                <FlexItem>
                    <VerticalSeparator />
                </FlexItem>
                <FlexItem>
                    Accept
                </FlexItem>
                <FlexItem>
                    <VerticalSeparator />
                </FlexItem>
                <FlexItem>
                    Reject
                </FlexItem>
            </FlexLayout>,
            key: 'hold',
            render: (value, object, index) => <FlexLayout itemGap="XL">
                <FlexItem><Checkbox
                    id="rounded_unchecked"
                    disabled={disabled}
                    type={Checkbox.CheckboxTypes.ROUNDED}
                    checked={object.isHold}
                    onChange={event => {
                        handleActionChange('hold', object.key, event.target.checked)
                    }}
                /></FlexItem>
                <FlexItem>
                    <VerticalSeparator />
                </FlexItem>
                <FlexItem><Checkbox
                    id="rounded_unchecked"
                    disabled={disabled}
                    type={Checkbox.CheckboxTypes.ROUNDED}
                    checked={object.isApprove}
                    onChange={event => {
                        handleActionChange('approve', object.key, event.target.checked)
                    }}
                /></FlexItem>
                <FlexItem>
                    <VerticalSeparator />
                </FlexItem>
                <FlexItem><Checkbox
                    id="rounded_unchecked"
                    disabled={disabled}
                    type={Checkbox.CheckboxTypes.ROUNDED}
                    checked={object.isReject}
                    onChange={event => {
                        handleActionChange('reject', object.key, event.target.checked)
                    }}
                /></FlexItem>
            </FlexLayout>
        },
    ]

    useEffect(() => {
        // to re-render table after any change
    }, [newChange])

    const handleActionChange = (action, objectID, value) => {
        const updatedViewChangeData = viewChangesData;
        updatedViewChangeData.forEach(obj => {
            if (obj.key === objectID) {
                if (action === 'reject') {
                    obj.isHold = false;
                    obj.isApprove = false;
                    obj.isReject = value;
                } else if (action === 'hold') {
                    obj.isHold = value;
                    obj.isApprove = false;
                    obj.isReject = false;
                } else if (action === 'approve') {
                    obj.isHold = false;
                    obj.isApprove = value;
                    obj.isReject = false;
                }
            }
        });
        setViewChangesData(updatedViewChangeData);
        setNewChange(prevState => !prevState);
        const updatedHold = [];
        const updatedReject = [];
        const updatedApprove = [];

        updatedViewChangeData.forEach(obj => {
            if (obj.isReject) {
                updatedReject.push(obj)
            } else if (obj.isHold) {
                updatedHold.push(obj)
            } else if (obj.isApprove) {
                updatedApprove.push(obj)
            }
        });
        setApprovedList(prevState => updatedApprove);
        setHoldList(prevState => updatedHold);
        setRejectedList(prevState => updatedReject);
        setNewChange(prevState => !prevState)
    }

    const handleResetBtn = () => {
        const updatedViewChangeData = viewChangesData;
        updatedViewChangeData.forEach(obj => {
            obj.isHold = false;
            obj.isApprove = false;
            obj.isReject = false;
        });
        setViewChangesData(updatedViewChangeData);
        setNewChange(prevState => !prevState)
    }

    const handleSelectedModalOpen = () => {
        setIsSelectedModalVisible(prevState => true);
    }

    const handleSelectedModalClose = () => {
        setIsSelectedModalVisible(prevState => false);
    }

    const handleRejectAlertModalClose = () => {
        setAlertRejectModalVisibile(prevState => false);
    }

    const handleRejectAlertModalOpen = () => {
        setAlertRejectModalVisibile(prevState => true);
    }

    const handleSubmitRejectAll = () => {
        setAlertRejectModalVisibile(prevState => false);
        putActionAll('REJECTED', duns, actionReason, batch_id, listType)
            .then(resp => {
                setActionReason(prevState => '');
                history.push('/dgo', {});
            })
    }

    const handleApproveAlertModalClose = () => {
        setAlertApproveModalVisibile(prevState => false);
    }

    const handleApproveAlertModalOpen = () => {
        setAlertApproveModalVisibile(prevState => true);
    }

    const handleSubmitApproveAll = () => {
        setAlertApproveModalVisibile(prevState => false);
        putActionAll('APPROVED', duns, actionReason, batch_id, listType)
            .then(resp => {
                setActionReason(prevState => '');
                history.push('/dgo', {});
            })
    }

    const handleHoldAlertModalClose = () => {
        setAlertHoldModalVisibile(prevState => false);
    }

    const handleHoldAlertModalOpen = () => {
        setAlertHoldModalVisibile(prevState => true);
    }

    const handleSubmitHoldAll = () => {
        setAlertHoldModalVisibile(prevState => false);
        putActionAll('HOLDING', duns, actionReason, batch_id, listType)
            .then(resp => {
                setActionReason(prevState => '');
                history.push('/dgo', {});
            })
    }

    const handleActionReasonChange = (e) => {
        setActionReason(e.target.value);
    }

    useEffect(() => {
        if (duns) {
            getDunsChanges(duns, batch_id)
                .then(res => {
                    setViewChangesData(prevState => res)
                    setLoading(prevState => false);
                })
                .catch(err => window.location.replace("/error"))
        }

    }, [duns]);

    const topSection = {
        title: 'Changes Audit',
        rightContent: <FlexLayout>
            <FlexItem><Button  disabled={disabled} type={Button.ButtonTypes.WARNING} onClick={handleHoldAlertModalOpen}>Hold All</Button></FlexItem>
            <FlexItem><Button  disabled={disabled} type={"destructive"} onClick={handleRejectAlertModalOpen}>Reject All</Button></FlexItem>
            <FlexItem><Button  disabled={disabled} type={Button.ButtonTypes.SUCCESS} onClick={handleApproveAlertModalOpen}>Accept All</Button></FlexItem>
        </FlexLayout>
    };

    const paginationKey = 'viewData'
    useEffect(() => {
        if(viewChangesData && viewChangesData.length){
            setPaginationForTab(paginationKey, {
                total: viewChangesData.length || 1,
                currentPage: pagination[paginationKey]?.currentPage || 1,
                pageSize: pagination[paginationKey]?.pageSize || 10,
              });
        }
      
    }, [viewChangesData])
    

    return <div className="AuditChangesContainer">
        <LocalTable
            columns={columns}
            dataSource={viewChangesData}
            pagination={pagination[paginationKey]}
            onChangePagination={(newPage) => setPaginationForTab(paginationKey, newPage)}
            loading={loading}
            topSection={topSection}
        />
        <FlexLayout justifyContent="flex-end">
            <FlexItem>
                <Button disabled={disabled} onClick={handleResetBtn}>
                    <RefreshIcon />
                    Reset
                </Button>
            </FlexItem>
            <FlexItem>
                <Button>
                    <Button onClick={handleSelectedModalOpen} disabled={viewChangesData.length === (approvedList.length + holdList.length + rejectedList.length) ? false : true} >
                        Submit
                    </Button>
                </Button>
            </FlexItem>
        </FlexLayout>
        <AllActionModal
            title="Do you want to Reject All"
            description={`Are you sure you want to reject the changes for Duns ID: ${duns} ? This action is irreversible.`}
            alertModalVisible={alertRejectModalVisible}
            buttonText={"Reject All"}
            submitCall={handleSubmitRejectAll}
            actionReason={actionReason}
            buttonAppereance={"destructive"}
            handleAlertModalClose={handleRejectAlertModalClose}
            handleActionReasonChange={handleActionReasonChange}
        />
        <AllActionModal
            title="Do you want to Approve All"
            description={`Are you sure you want to approve the changes for Duns ID: ${duns} ? This action is irreversible.`}
            alertModalVisible={alertApproveModalVisible}
            buttonText={"Approve All"}
            submitCall={handleSubmitApproveAll}
            actionReason={actionReason}
            buttonAppereance={Button.ButtonTypes.SUCCESS}
            handleAlertModalClose={handleApproveAlertModalClose}
            handleActionReasonChange={handleActionReasonChange}
        />
        <AllActionModal
            title="Do you want to Hold All"
            description={`Are you sure you want to hold the changes for Duns ID: ${duns} ? This action is irreversible.`}
            buttonText={"Hold All"}
            submitCall={handleSubmitHoldAll}
            actionReason={actionReason}
            buttonAppereance={Button.ButtonTypes.WARNING}
            alertModalVisible={alertHoldModalVisible}
            handleAlertModalClose={handleHoldAlertModalClose}
            handleActionReasonChange={handleActionReasonChange}
        />
        <SelectedActionModal visible={isSelectedModalVisible} handleClose={handleSelectedModalClose} data={viewChangesData} rejectedList={rejectedList} approvedList={approvedList} holdList={holdList} batch_id={batch_id} duns={duns} listType={listType} />
    </div>
}