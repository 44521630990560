import {
    DatePicker,
} from '@nutanix-ui/prism-reactjs';
import moment from 'moment';

import '../../styles.css';

export default function DateRangeFilter({ startDate, endDate, setStartDate, setEndDate }) {

    return <div>
        <label className="dateLabel">Date</label>
        <DatePicker
            name="datepicker-with-range"
            label="Date"
            data-test="range-select"
            oldDatePicker={false}
            value={{
                startDate: moment(startDate),
                endDate: moment(endDate)
            }}
            onChange={(selectedDate) => {
                if (selectedDate?.endDate) {
                    setEndDate(prevState => selectedDate.endDate.format('YYYY-MM-DD'))
                }
                if (selectedDate?.startDate) {
                    setStartDate(prevState => selectedDate.startDate.format('YYYY-MM-DD'))
                }
            }}
        />
    </div>
}