import React, { createContext, useState, useContext } from "react";

// Create DGO Context
const DGOContext = createContext();

// Initial Pagination State
const initialPaginationState = {
  total: 100,
  currentPage: 1,
  pageSize: 10,
};

// DGO Provider Component
export const DGOProvider = ({ children }) => {
  const [pagination, setPagination] = useState({
    pending: { ...initialPaginationState },
    holding: { ...initialPaginationState },
    rejected: { ...initialPaginationState },
    approved: { ...initialPaginationState },
    all: { ...initialPaginationState },
    viewData: { ...initialPaginationState },
  });
  const [activeTab, setActiveTab] = useState(0);

  const setPaginationForTab = (tab, newPagination) => {
    setPagination((prev) => ({
      ...prev,
      [tab]: { ...prev[tab], ...newPagination },
    }));
  };

  return (
    <DGOContext.Provider
      value={{
        pagination,
        activeTab,
        setActiveTab,
        setPaginationForTab,
      }}
    >
      {children}
    </DGOContext.Provider>
  );
};

// Custom Hook for consuming DGO Context
export const useDGOContext = () => {
  return useContext(DGOContext);
};
