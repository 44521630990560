import React, { useEffect, useState } from "react";
import { LocalTable, Sorter, Tabs, TextLabel, FlexLayout, Badge } from '@nutanix-ui/prism-reactjs';

import './styles.css';
import { getFileUploadHistory } from "../../Service/fileUploadUtility";


const columns = [{
    title: 'File ID',
    key: 'fileID'
}, {
    title: 'File Name',
    key: 'fileName'
}, {
    title: 'Last Uploaded On',
    key: 'time'
},
{
    title: 'Uploaded By',
    key: 'user'
},
{
    title: 'Status',
    key: 'status'
}];

export default function UploadHistory() {
    const [data, setData] = useState([{
        key: '',
        fileID: '',
        fileName: '',
        time: '',
        user: '',
        status: ''
    }]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        try {
            getFileUploadHistory()
                .then(resp => {
                    setData(resp);
                    setLoading(false);
                })
                .catch(err => {
                    window.location.replace('/error')
                })
        } catch (err) {

        }
    }, []);

    const pagination = {
        total: data.length,
        pageSize: 10,
        currentPage: 1,
        pageSizeOptions: [5, 10, 15]
    };

    const sort = {
        order: Sorter.SORT_ORDER_CONST.ASCEND,
        sortable: ['fileID', 'fileName', 'time', 'user']
    }

    const sorters = {
        fileID: (a, b) => {
            return a.fileID - b.fileID
        },
        fileName: (a, b) => {
            return a.fileName.localeCompare(b.fileName);
        },
        time: (a, b) => {
            return new Date(a.time).getTime() -
                new Date(b.time).getTime()
        },
        user: (a, b) => {
            return a.user.localeCompare(b.user);
        }
    };

    const tabsData = [
        {
            title: (
                <FlexLayout alignItems="center" itemSpacing="10px">
                    <TextLabel type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}>Upload File History</TextLabel>
                    <Badge type={Badge.BadgeTypes.TAG} count={data.length} />
                </FlexLayout>
            ),
            key: '1'
        }
    ]

    const customHeader = (
        <Tabs
            data={tabsData}
            data-testid="uploadFileHistoryTab"
            disabled="true"
            style={{ 'pointer-events': 'none' }}
        />
    );

    return <div className="uploadHistoryContainer" data-testid="uploadHistoryContainer">
        <LocalTable
            showCustomScrollbar={true}
            dataSource={data}
            className="historyTable"
            columns={columns}
            customHeader={customHeader}
            loading={loading}
            sort={sort}
            pagination={pagination}
            sorters={sorters}
            wrapperProps={{
                'data-test-id': 'fileUploadHistory'
            }}
        />
    </div>
}