import React from "react";

import {
    Line,
    LineChart,
    XAxis,
    Tooltip,
    ResponsiveContainer,
} from "@nutanix-ui/recharts";

import './styles.css';

export default function GraphDGO({ data, color }) {
    return <StatGraph data={data} color={color} />
}


const StatGraph = ({ width, color, data }) => {
    return (
        <ResponsiveContainer width={"100%"} height={50} className="DGOLineChart"
        >
            <LineChart
                data={data}
                label="Unique"
            >
                <XAxis dataKey={"name"} interval="preserveStartEnd" />
                <Line
                    key={"value"}
                    activeDot={true}
                    type="linear"
                    dataKey={"value"}
                    stroke={color}
                />
                <Tooltip
                    placement="bottom-center"
                />
            </LineChart>
        </ResponsiveContainer>
    )
}