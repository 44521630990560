import {
  Dashboard,
  DashboardWidgetLayout,
  DashboardWidgetHeader,
  FlexLayout,
  StackingLayout,
  TextLabel,
  Title,
  PlusIcon,
  Button,
  FlexItem,
  Badge,
  NotificationTrigger,
  Notification,
  Input,
  Loader,
  Tooltip,
  ButtonGroup,
  Table,
  Tabs,
  Sorter,
} from "@nutanix-ui/prism-reactjs";
import { Cell, Donut, DonutChart } from "@nutanix-ui/recharts";
import { TripleDotHorizontalIcon, Popover } from "@nutanix-ui/prism-reactjs";
import { useEffect, useState, useRef, useMemo } from "react";
import axios from "axios";
import { SERVER_URL } from "../../config/config";
import SelectDropdown from "../../hooks/SelectDropdown";
import {
  MenuIcon,
  DashboardIcon,
  ConfirmModal,
  Link,
} from "@nutanix-ui/prism-reactjs";

import { useHistory } from "react-router-dom";
import EmptyStates from "../../images/EmptyStates.png";
import { useNextGenCreateProject } from "../../../src/hooks";
import "./style.css";
import { uniqueId } from "lodash";
const propsData = {
  wrapperProps: {
    "data-test-id": "basic-usage",
  },
  layouts: {
    sm: [
      {
        i: "box1",
      },

      {
        i: "box2",
      },
      {
        i: "box3",
        w: 2,
      },
    ],
  },
};
export default function PaasDashboard(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [popOverVisible, setPopOverVisible] = useState(true);
  const { setProjectDetails } = useNextGenCreateProject();
  const [projectsList, setProjectsList] = useState([]);
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");
  const [seachedProjectsList, setSearchedProjectsList] = useState([]);
  const [projectID, setProjectID] = useState("");
  const [delePorjectModalOpen, setDeleteProjectModalOpen] = useState(false);
  const [activeView, setActiveView] = useState("card");
  let triggerRef = useRef();
  const handleVisibleChange = (visible) => {
    setPopOverVisible(visible);
    setTimeout(() => {
      setPopOverVisible(true);
    }, 1000);
  };
  const content = (userRole, projectID) => {
    return (
      <StackingLayout
        data-testid="paas-dashboard-card-header-more-dropdown"
        className="paas-dashboard-card-header-more-dropdown"
      >
        <FlexLayout flexDirection="column">
          <Button
            className="pop-up-content"
            data-testid="paas-dashboard-card-more-updateMember-btn"
            style={{ color: "#007bff" }}
            onClick={() => {
              handleVisibleChange(false);
              props.createProjectModalOpen(2);
              setProjectDetails({ project_id: projectID });
            }}
            type={Button.ButtonTypes.TEXT_SECONDARY}
          >
            Update Members
          </Button>
        </FlexLayout>

        <FlexLayout>
          <Button
            className="pop-up-content"
            data-testid="paas-dashboard-card-more-updateSources-btn"
            style={{ color: "#007bff" }}
            type={Button.ButtonTypes.TEXT_SECONDARY}
            onClick={() => {
              handleVisibleChange(false);
              props.createProjectModalOpen(3);
              setProjectDetails({ project_id: projectID });
            }}
          >
            Update Sources
          </Button>
        </FlexLayout>
        <FlexLayout>
          <Button
            style={{ color: "#F55656" }}
            className="pop-up-content"
            data-testid="paas-dashboard-card-more-deleteProject-btn"
            type={Button.ButtonTypes.TEXT_SECONDARY}
            onClick={() => {
              setDeleteProjectModalOpen(true);
              handleVisibleChange(false);
              setPopOverVisible(false);
              setProjectID(projectID);
            }}
          >
            Delete Project
          </Button>
        </FlexLayout>
      </StackingLayout>
    );
  };
  const handleDeleteProject = (projectID) => {
    let uname = localStorage.getItem("email");
    setDeleteProjectModalOpen(false);
    NotificationTrigger.add({
      id: "projects Deleted Alert info",
      className: "successAlert",
      message: "project Deletion request has been submitted",
      type: "info",
      autoDismissDelaySecs: 5,
    });
    setLoading(true);
    axios
      .post(`${SERVER_URL}/deleteProjectModel`, {
        project_id: projectID,
        user: uname,
      })
      .then((resp) => {
        if (resp?.data?.status === "200") {
          fetchProjectsDashboardModel();
          setDeleteProjectModalOpen(false);
          NotificationTrigger.add({
            id: "projects Deleted successfully",
            className: "successAlert",
            message: "projects Deleted successfully",
            type: "success",
            autoDismissDelaySecs: 5,
          });
        } else {
          setLoading(false);
          setDeleteProjectModalOpen(false);
          NotificationTrigger.add({
            id: "projects Deleted failed",
            className: "errorAlert",
            message: "Something went wrong. Please Refresh the page",
            type: "error",
            autoDismissDelaySecs: 5,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        setDeleteProjectModalOpen(false);
        NotificationTrigger.add({
          id: "projects Deleted failed",
          className: "errorAlert",
          message: "Something went wrong. Please Refresh the page",
          type: "error",
          autoDismissDelaySecs: 5,
        });
      });
  };
  const consructDefaultData = (item) => {
    return [
      {
        key: "completed",
        color: "#82EBB0",
        name: "Completed",
        value: item?.donut_chart?.pipe_completed,
      },
      {
        key: "run",
        color: "#FFDC75",
        name: "Running",
        value: item?.donut_chart?.pipe_running,
      },
      {
        key: "killed",
        color: "#F5948A",
        name: "Killed",
        value: item?.donut_chart?.pipe_killed,
      },
      {
        key: "not started",
        color: "#F2F4F6",
        name: "Not Started",
        value: item?.donut_chart?.pipe_not_started,
      },
      {
        key: "failed",
        color: "#F5948A",
        name: "Failed",
        value: item?.donut_chart?.pipe_failed,
      },
    ];
  };
  const renderDonutChart = (item) => {
    const startAngle = 90;
    const endAngle = -360 + startAngle;
    const size = 160 + 5 * 2;
    const donutData = [...consructDefaultData(item)];
    return (
      <DonutChart width={size} height={size}>
        <Donut
          data={donutData}
          innerRadius={150 / 2 - 10}
          outerRadius={150 / 2}
          dataKey="value"
          paddingAngle={0}
          startAngle={startAngle}
          endAngle={endAngle}
        >
          {donutData.map((entry, index) => {
            const { color, key } = entry;
            return <Cell key={key} fill={color} />;
          })}
        </Donut>
      </DonutChart>
    );
  };

  const renderDonutChartContainer = (item) => {
    return (
      <FlexLayout className="paas-dashboard-donut-chart">
        {renderDonutChart(item)}
        <FlexLayout class="paas-dashboard-donut-chat-overlay-label">
          {renderActiveLabel(item?.donut_chart?.pipe_total)}
        </FlexLayout>
      </FlexLayout>
    );
  };
  function renderActiveLabel(totalPipes) {
    let value, type;

    value = totalPipes;
    type = "Pipelines";

    return (
      <FlexLayout>
        <FlexLayout
          itemSpacing="15px"
          alignItems="center"
          flexDirection="column"
        >
          <Title size="h1" className="paas-dashboard-donut-label">
            {value}
          </Title>
          <TextLabel
            className="paas-dashboard-text-font"
            size={TextLabel.TEXT_LABEL_SIZE.MEDIUM}
            type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}
          >
            {type}
          </TextLabel>
        </FlexLayout>
      </FlexLayout>
    );
  }
  const dateFormatter = () => {
    let date = new Date();
    let ApiCalledDateTime = date.toLocaleString("en", {
      hour12: true,
      timeZone: "IST",
      month: "long",
      year: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    setLastUpdatedTime(ApiCalledDateTime);
  };
  const fetchProjectsDashboardModel = () => {
    setLoading(true);
    let uname = localStorage.getItem("email");
    axios
      .post(`${SERVER_URL}/getProjectsDashboardModel`, {
        team_id: "83f153",
        user: uname,
      })
      .then((resp) => {
        if (resp?.data?.data?.status === "200") {
          dateFormatter();
          setLoading(false);
          setProjectsList(resp?.data?.data?.project_list);
          setSearchedProjectsList(resp.data?.data?.project_list);
          NotificationTrigger.add({
            id: "projects Fetchted successfully",
            className: "successAlert",
            message: "projects Fetchted successfully",
            type: "success",
            autoDismissDelaySecs: 5,
          });
        } else {
          setLoading(false);
          NotificationTrigger.add({
            id: "projects Fetchted failed",
            className: "successAlert",
            message: "Something went wrong. Please Refresh the page",
            type: "error",
            autoDismissDelaySecs: 5,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        NotificationTrigger.add({
          id: "projects Fetchted failed",
          className: "successAlert",
          message: "Something went wrong. Please Refresh the page",
          type: "error",
          autoDismissDelaySecs: 5,
        });
      });
  };
  useEffect(() => {
    fetchProjectsDashboardModel();
    // eslint-disable-next-line
  }, [props.cancelClicked]);
  const onRefresh = () => {
    fetchProjectsDashboardModel();
  };
  const handleSearchPorjects = (projName) => {
    if (projName.length > 0) {
      const searchedProjects = projectsList.filter((proj) =>
        proj.project_name.toLowerCase().includes(projName.toLowerCase())
      );
      setSearchedProjectsList(searchedProjects);
    } else {
      setSearchedProjectsList(projectsList);
    }
  };
  const teamProps = {
    width: "auto",
    inputProps: {
      name: "team-selection",
      "data-testid": "team-selection",
    },
    selectProps: {
      autoSize: true,
      rowsData: [{ label: "Nutanix Internal", key: "Nutanix Internal" }],
    },
    selectedRow: { label: "Nutanix Internal", key: "Nutanix Internal" },
  };

  const buttons = [
    {
      view: "card",
      icon: <DashboardIcon aria-hidden="true" />,
      testId: "paas-dashboard-card-view",
    },
    { view: "list", icon: <MenuIcon />, testId: "paas-dashboard-list-view" },
  ];

  const getButtonStyle = (view) => ({
    backgroundColor: activeView === view ? "#4D5969" : "#FFFFFF",
    color: activeView === view ? "#FFFFFF" : "#000000",
  });
  return !loading && projectsList?.length === 0 ? (
    <center>
      <div className="paas-intial-screen">
        <img src={EmptyStates} className="emptyStates" alt="" />
        <p style={{ fontSize: "16px" }}>Create Project</p>
        <p style={{ color: "grey" }}>
          Once you create a project, it will be available here from <br />{" "}
          registered platforms to view and manage.
        </p>
        <Button
          type="primary"
          onClick={() => props.createProjectModalOpen(0)}
          data-testid="paas-card-create-project-btn"
        >
          Create Project <PlusIcon aria-hidden="true" size="small" />
        </Button>
      </div>
    </center>
  ) : (
    <>
      <Notification>
        <div className="paas-notification" />
      </Notification>
      <div className="dashboard-container">
        <div className="dashboard-left-container">
          <h3 className="dashboard-label">Paas Project's Dashboard</h3>
        </div>

        <div className="dashboard-right-container">
          <span className="dashboard-recent-update">
            Last Update: {lastUpdatedTime || "Fetching..."}
          </span>
          <div className="vertical-border"></div>
          <Link
            to="#"
            onClick={onRefresh}
            data-testid="paas-dashboard-refresh-btn"
          >
            Refresh
          </Link>
          <div className="vertical-border"></div>{" "}
          <span className="dashboard-recent-update" style={{ color: "black" }}>
            Team:
          </span>
          <SelectDropdown
            {...teamProps}
            data-testid="paas-dashboard-team-selection"
          />
        </div>
      </div>
      <StackingLayout
        className="paas-dashboard-search"
        itemSpacing="40px"
        marginBottom="-20%"
      >
        <FlexLayout justifyContent="space-between" style={{ margin: "1%" }}>
          <Input
            data-testid="paas-dashboard-search-project"
            search={true}
            style={{ width: "295px" }}
            name="input-success-suffix"
            placeholder="search project"
            defaultValue=""
            onChange={(e) => {
              handleSearchPorjects(e.target.value);
            }}
          />
          <FlexLayout justifyContent="" alignItems="center" itemSpacing="5px">
            <ButtonGroup>
              {buttons.map(({ view, icon, testId }) => (
                <Button
                  key={view}
                  style={getButtonStyle(view)}
                  data-testid={testId}
                  onClick={() => setActiveView(view)}
                >
                  {icon}
                </Button>
              ))}
            </ButtonGroup>
            <Button
              data-testid="paas-create-project-btn"
              className="paas-dashboard-text-font"
              style={{ marginLeft: "20px" }}
              onClick={() => props.createProjectModalOpen(0)}
              type={Button.ButtonTypes.NAVIGATION_PRIMARY}
              aria-label="Save this config"
            >
              Create Project <PlusIcon aria-hidden="true" size="small" />
            </Button>
          </FlexLayout>
        </FlexLayout>
      </StackingLayout>

      <div className="etlListing">
        {activeView === "card" && (
          <Loader
            loading={loading}
            tip="Loading..."
            className="paas-dashboard-loader"
            data-test-id="overlay"
          >
            <Dashboard
              {...propsData}
              isDraggable={false}
              isResizable={false}
              breakpoints={{ lg: 2000, md: 1400, sm: 1050, xs: 750, xxs: 400 }}
              cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 }}
              className="paas-dashboard"
            >
              {seachedProjectsList &&
                seachedProjectsList?.length > 0 &&
                seachedProjectsList?.map((item) => (
                  <div key={item.project_id}>
                    <DashboardWidgetLayout
                      header={
                        <DashboardWidgetHeader
                          title={
                            <FlexLayout
                              style={{ width: "100%" }}
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FlexItem>
                                <Link
                                  data-testid="paas-dashboard-card-header-link"
                                  type="secondary"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/ETL_listing",
                                      state: {
                                        Project: {
                                          key: 0,
                                          label: item?.project_name,
                                          value: item?.project_id,
                                        },
                                      },
                                    })
                                  }
                                >
                                  <Tooltip
                                    oldTooltip={false}
                                    appearance={Tooltip.APPEARANCE.PRIMARY}
                                    content={item.project_name.toUpperCase()}
                                    placement="top"
                                  >
                                    <div>
                                      {item?.project_name
                                        ? item?.project_name
                                            .slice(0, 25)
                                            .toUpperCase()
                                        : ""}
                                    </div>
                                  </Tooltip>
                                </Link>
                              </FlexItem>
                              <FlexItem
                                style={{ display: "flex", marginRight: "1%" }}
                                padding="10px"
                              >
                                <Badge
                                  color={
                                    item.user_role === "Admin"
                                      ? "#d7f6e0"
                                      : item.user_role === "Edit" || "Editor"
                                      ? "#d3edfd"
                                      : "#e1e5e9"
                                  }
                                  count={
                                    <span
                                      style={{
                                        color:
                                          item.user_role === "Admin"
                                            ? "#1f794e"
                                            : item.user_role === "Edit" ||
                                              "Editor"
                                            ? "#1956ad"
                                            : "#6c7c8e",
                                      }}
                                    >
                                      {item.user_role}
                                    </span>
                                  }
                                />
                                {item?.user_role === "Admin" ? (
                                  popOverVisible ? (
                                    <Popover
                                      content={content(
                                        item?.user_role,
                                        item?.project_id
                                      )}
                                      onPopupVisibleChange={() =>
                                        handleVisibleChange(true)
                                      }
                                      popupPlacement="bottom"
                                    >
                                      <div
                                        data-testid="paas-dashboard-card-header-more-btn"
                                        ref={triggerRef}
                                        style={{ padding: "10px" }}
                                      >
                                        <TripleDotHorizontalIcon />
                                      </div>
                                    </Popover>
                                  ) : (
                                    <div
                                      ref={triggerRef}
                                      style={{ padding: "10px" }}
                                    >
                                      <TripleDotHorizontalIcon />
                                    </div>
                                  )
                                ) : (
                                  ""
                                )}
                              </FlexItem>
                            </FlexLayout>
                          }
                          showCloseIcon={false}
                        />
                      }
                      bodyContent={
                        <>
                          {item?.donut_chart?.pipe_total === 0 ? (
                            <StackingLayout
                              width="100%"
                              style={{
                                marginTop: "10%",
                                marginLeft: "35%",
                                overflow: "hidden",
                              }}
                            >
                              <center>
                                <div>
                                  <FlexLayout
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <img
                                      src={EmptyStates}
                                      width={"100px"}
                                      height={"100px"}
                                      alt=""
                                    />
                                  </FlexLayout>
                                  <FlexLayout justifyContent="center">
                                    <Link
                                      data-testid="paas-dashboard-card-body-link"
                                      onClick={() =>
                                        history.push({
                                          pathname: "/ETL_listing",
                                          state: {
                                            Project: {
                                              key: 0,
                                              label: item?.project_name,
                                              value: item?.project_id,
                                            },
                                            addPipeLineVisible: true,
                                          },
                                        })
                                      }
                                      className="paas-dashboard-text-font"
                                      style={{
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {" "}
                                      <PlusIcon
                                        aria-hidden="true"
                                        size="small"
                                      />{" "}
                                      Add Pipeline
                                    </Link>
                                  </FlexLayout>
                                </div>
                              </center>
                            </StackingLayout>
                          ) : (
                            <StackingLayout
                              style={{
                                width: "100%",
                                marginTop: "3%",
                                overflow: "hidden",
                              }}
                              alignItems="center"
                            >
                              <FlexLayout
                                justifyContent="space-around"
                                alignItems="center"
                              >
                                <FlexItem justifyContent="flex-start">
                                  {renderDonutChartContainer(item)}
                                </FlexItem>
                                <FlexLayout
                                  flexDirection="column"
                                  style={{
                                    marginRight: "15%",
                                    marginTop: "1%",
                                    marginLeft: "2%",
                                  }}
                                >
                                  {[1, 2, 3, 4, 5]?.map((i) => (
                                    <FlexLayout key={i}>
                                      <Badge
                                        appearance={
                                          Badge.BadgeAppearance.NAVIGATION
                                        }
                                        color={
                                          i === 1
                                            ? "#82EBB0"
                                            : i === 2
                                            ? "#FFDC75"
                                            : i === 3
                                            ? "#F55656"
                                            : i === 4
                                            ? "#F2F4F6"
                                            : "#F55656"
                                        }
                                        text="1"
                                      />
                                      <TextLabel
                                        className="paas-dashboard-text-font"
                                        style={{ marginLeft: "100%" }}
                                        type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}
                                      >
                                        {i === 1
                                          ? item?.donut_chart?.pipe_completed
                                          : i === 2
                                          ? item?.donut_chart?.pipe_running
                                          : i === 3
                                          ? item?.donut_chart?.pipe_killed
                                          : i === 4
                                          ? item?.donut_chart?.pipe_not_started
                                          : item?.donut_chart?.pipe_failed}
                                      </TextLabel>
                                      <TextLabel
                                        className="paas-dashboard-text-font"
                                        style={{
                                          whiteSpace: "nowrap",
                                          marginLeft: "-110%",
                                        }}
                                        type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}
                                      >
                                        {i === 1
                                          ? "Completed"
                                          : i === 2
                                          ? "Running"
                                          : i === 3
                                          ? "Killed"
                                          : i === 4
                                          ? "Not Started"
                                          : "Failed"}
                                      </TextLabel>
                                    </FlexLayout>
                                  ))}
                                </FlexLayout>
                              </FlexLayout>
                            </StackingLayout>
                          )}
                        </>
                      }
                      footer={
                        <StackingLayout
                          style={{ width: "100%" }}
                          justifyContent="space-between"
                        >
                          <FlexLayout
                            style={{ width: "100%", paddingRight: "7px" }}
                            justifyContent="space-between"
                            alignItems="center"
                            margin="1%"
                          >
                            {[1, 2, 3, 4].map((i) => (
                              <FlexItem key={i} justifyContent="space-between">
                                <TextLabel
                                  className="paas-dashboard-card-footer-textValue"
                                  display={TextLabel.TEXT_LABEL_DISPLAY.BLOCK}
                                  type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}
                                >
                                  {i === 1
                                    ? item?.active_pipe_pct + "%"
                                    : i === 2
                                    ? item?.users
                                    : i === 3
                                    ? item?.sources
                                    : item?.alerting_pipes}
                                </TextLabel>
                                <TextLabel
                                  className="paas-dashboard-card-footer-text paas-dashboard-text-font"
                                  display={TextLabel.TEXT_LABEL_DISPLAY.BLOCK}
                                  type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}
                                >
                                  {i === 1
                                    ? "Active Pipelines"
                                    : i === 2
                                    ? "Users"
                                    : i === 3
                                    ? "Sources"
                                    : "Alerting On"}
                                </TextLabel>
                              </FlexItem>
                            ))}
                          </FlexLayout>
                        </StackingLayout>
                      }
                    />
                  </div>
                ))}
            </Dashboard>
          </Loader>
        )}
        {activeView !== "card" && (
          <PaasListView
            seachedProjectsList={seachedProjectsList}
            onRefresh={onRefresh}
            setProjectDetails={setProjectDetails}
            handleVisibleChange={handleVisibleChange}
            props={props}
            setProjectID={setProjectID}
            setPopOverVisible={setPopOverVisible}
            setDeleteProjectModalOpen={setDeleteProjectModalOpen}
            loading={loading}
          />
        )}
      </div>
      {
        <ConfirmModal
          visible={delePorjectModalOpen}
          onCancel={() => setDeleteProjectModalOpen(false)}
          onConfirm={() => handleDeleteProject(projectID)}
        >
          Are you sure you want delete Project?
        </ConfirmModal>
      }
    </>
  );
}

const PaasListView = ({
  handleVisibleChange,
  props,
  setProjectDetails,
  setProjectID,
  setPopOverVisible,
  setDeleteProjectModalOpen,
  seachedProjectsList,
  loading,
}) => {
  const history = useHistory();
  const panelIdPrefix = useMemo(() => uniqueId("tabs-panel-"), []);
  const [currentTab, setCurrentTab] = useState("1");

  const [sort, setSort] = useState({
    order: Sorter.SORT_ORDER_CONST.ASCEND,
    column: "project_name",
    sortable: [
      "project_name",
      "access",
      "active_percentage",
      "users",
      "sources",
      "alerting_on",
      "total_pipelines",
      "not_started",
      "running",
      "completed",
      "killed",
      "failed",
    ],
  });

  const [pagination, setPagination] = useState({
    total: 1,
    pageSize: 5,
    currentPage: 1,
    pageSizeOptions: [5, 10, 15],
  });

  const structure = useMemo(
    () => ({
      columnResize: true,
      columnWidths: {
        project_name: "202px",
        access: "81px",
        active_percentage: "89px",
        users: "74px",
        sources: "86px",
        alerting_on: "104px",
        total_pipelines: "120px",
        not_started: "104px",
        running: "102px",
        completed: "102px",
        killed: "73px",
        failed: "76px",
      },
      paginationPosition: {
        top: true,
        bottom: true,
      },
    }),
    []
  );

  const columns = useMemo(
    () => [
      { title: "Project Name", key: "project_name", width: 202 },
      {
        title: "Access",
        key: "access",
        width: 81,
        render: (name) => {
          const getColor = (access) => {
            switch (access.toLowerCase()) {
              case "admin":
                return "green";
              case "editor":
                return "blue";
              case "viewer":
                return "#F2F4F6";
              default:
                return "#F2F4F6";
            }
          };
          const capitalizeWords = (input) =>
            input
              .split(" ")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ");
          return (
            <Badge
              type={Badge.BadgeTypes.TAG}
              color={getColor(name)}
              count={capitalizeWords(name) || "No Access"}
              style={{
                color: name === "viewer" && "#627386",
                backgroundColor: name === "viewer" && getColor(name),
              }}
            />
          );
        },
      },
      { title: "Active %", key: "active_percentage", width: 89 },
      { title: "Users", key: "users", width: 74 },
      { title: "Sources", key: "sources", width: 86 },
      { title: "Alerting On", key: "alerting_on", width: 104 },
      { title: "Total Pipelines", key: "total_pipelines", width: 120 },
      { title: "Not Started", key: "not_started", width: 104 },
      { title: "Running", key: "running", width: 87 },
      { title: "Completed", key: "completed", width: 102 },
      { title: "Killed", key: "killed", width: 73 },
      { title: "Failed", key: "failed", width: 73 },
    ],
    []
  );

  const data = useMemo(
    () =>
      seachedProjectsList.map((item, index) => ({
        key: (index + 1).toString(),
        project_name: (
          <Link
            data-testid="paas-dashboard-card-header-link"
            type="secondary"
            onClick={() =>
              history.push({
                pathname: "/ETL_listing",
                state: {
                  Project: {
                    key: 0,
                    label: item?.project_name,
                    value: item?.project_id,
                  },
                },
              })
            }
          >
            <Tooltip
              oldTooltip={false}
              appearance={Tooltip.APPEARANCE.PRIMARY}
              content={item.project_name.toUpperCase()}
              placement="top"
            >
              <div>
                {item?.project_name
                  ? item?.project_name.slice(0, 25).toUpperCase()
                  : ""}
              </div>
            </Tooltip>
          </Link>
        ),
        project_id: item.project_id,
        access: item.user_role.toLowerCase(),
        active_percentage: item.active_pipe_pct.toString(),
        users: item.users.toString(),
        sources: item.sources.toString(),
        alerting_on: item.alerting_pipes.toString(),
        total_pipelines: item.donut_chart.pipe_total.toString(),
        not_started: item.donut_chart.pipe_not_started.toString(),
        running: item.donut_chart.pipe_running.toString(),
        completed: item.donut_chart.pipe_completed.toString(),
        killed: item.donut_chart.pipe_killed.toString(),
        failed: item.donut_chart.pipe_failed.toString(),
      })),
    [history, seachedProjectsList]
  );

  const filteredData = useMemo(() => {
    const filterDataByTab = (tabKey) => {
      switch (tabKey) {
        case "2": // Failed
          return data.filter((item) => item.failed > 0);
        case "3": // Running
          return data.filter((item) => item.running > 0);
        case "4": // Completed
          return data.filter((item) => item.completed > 0);
        case "5": // Killed
          return data.filter((item) => item.killed > 0);
        case "6": // Not Started
          return data.filter((item) => item.not_started > 0);
        default: // All
          return data;
      }
    };
    return filterDataByTab(currentTab);
  }, [data, currentTab]);

  const sortedData = useMemo(() => {
    const newData = [...filteredData];
    if (!sort.column) return newData;
    newData.sort((a, b) => {
      const aValue = a[sort.column];
      const bValue = b[sort.column];
      let result =
        typeof aValue === "number" && typeof bValue === "number"
          ? aValue - bValue
          : String(aValue).localeCompare(String(bValue));
      return sort.order === Sorter.SORT_ORDER_CONST.DESCEND ? -result : result;
    });
    return newData;
  }, [filteredData, sort.column, sort.order]);

  const createTabItem = (label, count, key) => ({
    title: (
      <FlexLayout alignItems="center" itemSpacing="10px">
        <TextLabel type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}>{label}</TextLabel>
        <Badge
          color={label === "Failed" ? "#F55656" : "#F2F4F6"}
          // className={ label === "Failed" ? "FailedBadgeTag" : "" }
          type={Badge.BadgeTypes.TAG}
          // appearance={ Badge.BadgeAppearance.NAVIGATION }
          count={count}
          style={{
            backgroundColor: label === "Failed" ? "#F55656" : "#F2F4F6",
            color: label === "Failed" ? "#FFFFFF" : "#627386",
          }}
        />
      </FlexLayout>
    ),
    key,
  });

  const useTabsData = () => {
    return useMemo(() => {
      const allCount = data.length;
      const failedCount = data.filter((item) => item.failed > 0).length;
      const runningCount = data.filter((item) => item.running > 0).length;
      const completedCount = data.filter((item) => item.completed > 0).length;
      const killedCount = data.filter((item) => item.killed > 0).length;
      const notStartedCount = data.filter(
        (item) => item.not_started > 0
      ).length;

      return [
        createTabItem("All", allCount, "1"),
        createTabItem("Failed", failedCount, "2"),
        createTabItem("Running", runningCount, "3"),
        createTabItem("Completed", completedCount, "4"),
        createTabItem("Killed", killedCount, "5"),
        createTabItem("Not Started", notStartedCount, "6"),
      ];
    }, [seachedProjectsList]);
  };

  const tabsData = useTabsData();

  const handlePaginationChange = (newPagination) => {
    setPagination(newPagination);
  };

  const handleChangeSort = (newSort) => {
    setSort({ ...sort, ...newSort });
  };

  const customHeader = (
    <Tabs
      adaptive={true}
      data={tabsData}
      onTabClick={(item) => setCurrentTab(item)}
      defaultActiveKey="1"
      padding="0px-15px"
      panelIdPrefix={panelIdPrefix}
    />
  );

  useEffect(() => {
    if (sortedData.length) {
      setPagination((prev) => ({
        ...prev,
        total: sortedData.length || 1,
      }));
    }
  }, [sortedData.length]);

  const topSection = useMemo(
    () => ({
      title: `Viewing all ${sortedData.length} data set`,
      // leftContent: <div>Left content</div>,
      // rightContent: <div>Right content</div>
    }),
    [sortedData.length]
  );

  const handleRowAction = async (key, rowData) => {
    if (key === "update_member") {
      handleVisibleChange(false);
      props.createProjectModalOpen(2);
      setProjectDetails({ project_id: rowData.project_id });
    } else if (key === "update_source") {
      handleVisibleChange(false);
      props.createProjectModalOpen(3);
      setProjectDetails({ project_id: rowData.project_id });
    } else {
      setDeleteProjectModalOpen(true);
      handleVisibleChange(false);
      setPopOverVisible(false);
      setProjectID(rowData.project_id);
    }
  };

  const rowAction = {
    actions: [
      {
        key: "update_member",
        value: "Update Members",
        id: "update-member-action", // Add unique id
        className: "update-member-class", // Add class name
      },
      {
        key: "update_source",
        value: "Update Sources",
        id: "update-source-action",
        className: "update-source-class",
      },
      {
        key: "delete_project",
        value: "Delete Project",
        id: "delete-project-action",
        className: "delete-project-class",
      },
    ],
    onRowAction: handleRowAction,
  };

  return (
    <div className="PaasListContainer">
      <Table
        showCustomScrollbar={true}
        dataSource={sortedData}
        columns={columns}
        customHeader={customHeader}
        sort={sort}
        onChangeSort={handleChangeSort}
        pagination={pagination}
        onChangePagination={handlePaginationChange}
        structure={structure}
        topSection={topSection}
        id={`${panelIdPrefix}-${currentTab}`}
        rowAction={rowAction}
        loading={loading}
        wrapperProps={{
          "data-test-id": "customized-options-table-no-data",
        }}
      />
    </div>
  );
};