import React, { useState, useEffect } from 'react';
import {
    FlexLayout,
    DownloadIcon, Select
} from '@nutanix-ui/prism-reactjs';

import { downloadTemplate } from '../../../../utils/csvDownloadUtlity';

import { getTemplateData } from '../../../../Service/fileUploadUtility';

export default function TemplateSelect(props) {
    const [selectedRow, setSelectedRow] = useState(null);

    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [inputProps, setInputProps] = useState({ value: '' });
    useEffect(() => {
        getTemplateData()
        .then(resp => {
            setData(resp);
            setSearchData(resp);
            setLoading(false);
        })
        .catch(err => {
            window.location.replace('/error')
        })
    }, []);

    const handleOnSelectedChange = (row) => {
        setInputProps({ value: row?.label || '' });
        setSelectedRow(row);
        props.handleTemplateSelect(row);
    }

    const handleDownloadClick = (row) => {
        downloadTemplate(selectedRow);
    }
    const  handleOnInputValueChange=(event) =>{
    const {value}=event.target;
    setInputProps({ value });
        if(event?.target?.value?.length>0){
                const newRows = data.filter((row) => {
                return (
                    row.label.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 
                );
                });
                setSearchData(newRows);
        }else{
            setSearchData(data);
        }
    }

    return <Select style={{ maxWidth: '440px', width: '300px' }}
        searchable= {true}
        rowsData={searchData}
        placeholder='Select Template Type'
        data-testid="templateSelect"
        onSelectedChange={handleOnSelectedChange}
        selectedRow={selectedRow}
        loading={loading}
        addonAfter={
            <DownloadIcon onClick={handleDownloadClick} data-testid="downloadIcon"/>
        }
        inputProps= {{
            value: inputProps.value,
            onChange: (event)=>handleOnInputValueChange(event),
            name:'searchable-select'
          }}
        rowRenderer={(row) => <div data-testid="templateRows">{row.label}</div>}
    />
}