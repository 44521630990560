// SecondPage.js
import React from 'react';
import { useLocation } from 'react-router-dom';

import '../../styles.css';
import ViewDUNChangesHeader from './Header';
import OverviewTable from './OverViewTable';
import DunsAuditTable from './AuditTable';

function ViewDunsChanges() {
    const location = useLocation();
    const { state } = location; // Access the state passed from HomePage
    return (
        <div className='ViewDunChangesMainContainer'>
            <ViewDUNChangesHeader />
            <OverviewTable data={[state]} />
            <DunsAuditTable duns={state.duns} batch_id={state.id} listType={state.type}/>
        </div>
    );
}

export default ViewDunsChanges;
