import {
  Badge,
  LocalTable,
  AlertTriangleIcon,
  Tooltip,
} from "@nutanix-ui/prism-reactjs";
import { useHistory } from "react-router-dom";

import './styles.css';
const EVENT_TYPE = [
    "TRANSFER",
    "UPDATE",
    "DELETE",
    "REVIEWED",
    "UNDER REVIEW"
]

export default function PendingTable(props) {
    const history = useHistory();
    const columns = [
        {
            title: 'ID',
            key: 'id'
        },
        {
            title: 'Duns',
            key: 'duns'
        },
        {
            title: 'Date',
            key: 'date'
        },
        {
            title: 'SFDC ID',
            key: 'sfdc_id',
            render: (value, object, index) => <p className='sfdc_id_columns'>{object.sfdc_id}</p>
        },
        {
            title: 'Account Type',
            key: 'account_type'
        },
        {
            title: 'SFDC Account Name',
            key: 'sfdc_account_name'
        },
        {
            title: 'Theatre',
            key: 'theatre'
        },
        {
            title: 'Region',
            key: 'region'
        },
        {
            title: 'Sub Region',
            key: 'sub_region'
        },
        {
            title: 'Event',
            key: 'event_type',
            render: (value, object, index) => {
                if (object.event_type === 'DELETE') {
                    return <div className='eventTypeColumn'>
                        <Badge color="red" text={object.event_type} />
                    </div>
                }
                else if (object.event_type === 'REVIEWED') {
                    return <div className='eventTypeColumn'>
                        <Badge color="green" text={object.event_type} />
                    </div>
                } else if (object.event_type === 'TRANSFER') {
                    const retainedDuns = <div className='retainedDunsContainer'>
                        <h2>Retained Duns</h2>
                        <p>{object.retained_duns}</p>
                    </div>
                    return <div className='eventTypeColumn' style={{width: '8rem'}}>
                        <Badge color="blue" text={object.event_type} />
                        <Tooltip
                            oldTooltip={false}
                            appearance={Tooltip.APPEARANCE.SECONDARY}
                            mouseEnterDelay={300}
                            content={retainedDuns}
                            contentProps={{
                                style: {
                                    maxWidth: 300
                                },
                                'data-test-id': 'basic-tooltip',
                                className: 'custom-content-classname'
                            }}

                        >
                            <AlertTriangleIcon color="#D3D3D3" />
                        </Tooltip>
                    </div>
                } else if (object.event_type === 'UNDER REVIEW') {
                    return <div className='eventTypeColumn'>
                        <Badge color="yellow" text={object.event_type} />
                    </div>
                }
                return <div className='eventTypeColumn'>
                    <Badge color="gray" text={object.event_type} />
                </div>
            }
        },
        {
            title: 'Changes',
            key: 'changes',
            render: (value, object, index) => {
                if (object.event_type === 'UPDATE') {
                    return <div className='tableActionColumn'>
                        <Badge color="blue" count={object.changes} />
                        <a onClick={() => handleNavigation(object)}>View</a>
                    </div>
                }
                return <div className='tableActionColumn'>
                    <Badge color="blue" count={object.changes} />
                </div>
            }
        }];

    const handleNavigation = (object) => {
        object["type"] = props.type;
        history.push('/dunsChanges', object);
    }
    const data = [];
    for (let i = 0; i < 100; i++) {
        data.push({
            key: i,
            id: i,
            duns: `name${i}`,
            date: '24-06-2004',
            sfdc_id: `address${i}address${i}address${i}address${i}address${i}address${i}`,
            account_type: Math.random().toString(36).slice(2, 7),
            sfdc_account_name: Math.random().toString(36).slice(2, 7),
            theatre: Math.random().toString(36).slice(2, 7),
            region: Math.random().toString(36).slice(2, 7),
            sub_region: Math.random().toString(36).slice(2, 7),
            event_type: EVENT_TYPE[Math.floor(Math.random() * 5)],
            changes: Math.floor(Math.random() * 100)
        });
    }

    const sort = {
        sortable: ['id', 'duns', 'date', 'sfdc_id', 'account_type', 'sfdc_account_name',
            'theatre', 'region', 'sub_region', 'event_type', 'changes']
    };

    const sorters = {
        id: (a, b) => {
            return a.id - b.id;
        },
        duns: (a, b) => {
            return a.duns.localeCompare(b.duns);
        },
        date: (a, b) => {
            return a.date.localeCompare(b.date)
        },
        sfdc_id: (a, b) => {
            return a.sfdc_id.localeCompare(b.sfdc_id)
        },
        account_type: (a, b) => {
            return a.account_type.localeCompare(b.account_type)
        },
        sfdc_account_name: (a, b) => {
            return a.sfdc_account_name.localeCompare(b.sfdc_account_name)
        },
        theatre: (a, b) => {
            return a.theatre.localeCompare(b.theatre)
        },
        region: (a, b) => {
            return a.region.localeCompare(b.region)
        },
        sub_region: (a, b) => {
            return a.sub_region.localeCompare(b.sub_region)
        },
        event_type: (a, b) => {
            return a.event_type.localeCompare(b.event_type)
        },
        changes: (a, b) => {
            return a.changes - b.changes
        }
    };

  return (
    <LocalTable
      columns={columns}
      dataSource={props?.recordData || []}
      pagination={props?.pagination}
      onChangePagination={props?.handlePaginationChange}
      sort={sort}
      loading={props?.loading}
      sorters={sorters}
    />
  )
}
