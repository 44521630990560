import React from "react";
import { Button, FlexItem, FlexLayout, Modal, StackingLayout, FormItemInput } from "@nutanix-ui/prism-reactjs";

export default function AllActionModal({ title, buttonText, description, alertModalVisible, handleAlertModalClose, buttonAppereance, actionReason, handleActionReasonChange, submitCall }) {

    return <Modal
        visible={alertModalVisible}
        title={<h2>{title}</h2>}
        onClose={handleAlertModalClose}
        restoreFocus={true}
        closeButtonProps={{ 'data-test': 'my-close-icon-data-test' }}
        primaryButtonOnClick={handleAlertModalClose}
        footer={null}
    >
        <StackingLayout padding="20px">
            <p style={{ wordBreak: 'break-word', fontSize: '14px' }}>
                {description}
            </p>
            <FormItemInput className="textField" placeholder="Type Reason Here" width='100%'
                onChange={handleActionReasonChange} value={actionReason} />
            <FlexLayout justifyContent="center" padding="10px">
                <FlexItem>
                    <Button onClick={handleAlertModalClose}>
                        Cancel
                    </Button>
                </FlexItem>
                <FlexItem>
                    <Button type={buttonAppereance} onClick={submitCall} disabled={actionReason.length > 0 ? false : true}>
                        {buttonText}
                    </Button>
                </FlexItem>
            </FlexLayout>
        </StackingLayout>
    </Modal>
}