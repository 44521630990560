import { useState } from "react";
import { Modal } from "antd";
import CreateProjectModal from "./CreateProjectModal";
import { NextGenCreateProjectContext } from "../../config/contexts";
import "./NextGenEtl.css";

import PaasDashboard from "../ETLCanvas/PaasDashboard";
const NextGenETL = (props) => {
  const [createProjModalVisible, setCreateProjModalVisible] = useState(false);
  const [projectDetails, setProjectDetails] = useState({});
  const [tabKey, setTabKey] = useState(1);
  const [cancelClicked, setClicked] = useState(false);

  const handleProjModalCancel = () => {
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 1000);
    setCreateProjModalVisible(false);
  };
  const createProjectModalOpen = (tabNo) => {
    setCreateProjModalVisible(true);
    setTabKey(tabNo);
  };
  return (
    <NextGenCreateProjectContext.Provider
      value={{ projectDetails, setProjectDetails }}
    >
      <div>
        <PaasDashboard
          createProjectModalOpen={createProjectModalOpen}
          cancelClicked={cancelClicked}
        />
        <div>
          {createProjModalVisible && (
            <Modal
              mask={true}
              centered={true}
              title={"Create a Project"}
              open={createProjModalVisible}
              onCancel={handleProjModalCancel}
              footer={false}
              width={"70vw"}
              style={{
                maxWidth: "700px",
                maxHeight: "600px",
                fontFamily: "Nutanix Soft",
              }}
            >
              <CreateProjectModal
                onCancel={handleProjModalCancel}
                tabNo={tabKey}
              />
            </Modal>
          )}
        </div>
      </div>
    </NextGenCreateProjectContext.Provider>
  );
};

export default NextGenETL;
