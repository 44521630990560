import React from "react";
import { Badge, ChevronLeftIcon, FlexLayout, Separator, VerticalSeparator } from "@nutanix-ui/prism-reactjs";
import { useHistory } from "react-router-dom";
import '../../styles.css';

export default function ViewDUNChangesHeader() {
    const history = useHistory();
    const handleBackNavigation = () => {
        history.push("/dgo");
    };

    return <div className="viewChangesHeaderContainer">
        <div>
            <FlexLayout alignItems="center">
                <Separator separator={<VerticalSeparator data-testid='separator' />}>
                    <ChevronLeftIcon onClick={handleBackNavigation} size='large' color="#22a5f7" style={{cursor:'pointer'}} />
                    <Badge
                        color="gray"
                        count="Dashboard"
                    />
                </Separator>
                <h3 data-testid='header'>Changes Audit</h3>
            </FlexLayout>
        </div>
    </div>
}