import { useState, useEffect } from "react";
import React from 'react';
import { Collapse, Table, Button, Alert, Spin } from "antd";
import { apiResponseError } from "../../config/helper";
import axios from "axios";
import "./NextGenEtl.css";
import { SERVER_URL } from "../../config/config";
import { useNextGenCreateProject } from '../../hooks'
const { Panel } = Collapse;
const projectColumns = [
    {
        title: 'Project Name',
        dataIndex: 'project_name',
        sorter: (a, b) => {
            a = String(a.project_name) || "";
            b = String(b.project_name) || "";

            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: 'Description',
        dataIndex: 'description',
    }]

const teamColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => {
            a = String(a.name) || "";
            b = String(b.name) || "";

            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: 'Email',
        dataIndex: 'email',
        sorter: (a, b) => {
            a = String(a.email) || "";
            b = String(b.email) || "";

            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: 'Access',
        dataIndex: 'access',
        sorter: (a, b) => {
            a = String(a.access) || "";
            b = String(b.access) || "";

            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        },
    }
]

const sourceColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => {
            a = String(a.name) || "";
            b = String(b.name) || "";

            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: 'Database',
        dataIndex: 'database',
        render:(value)=>{
            return value ||'N/A'
        }

    },
    {
        title: 'Database Type',
        dataIndex: 'database_type',

    },
    {
        title: 'Host Name',
        dataIndex: 'host_name',

        render:(value)=>{
            return value ||'N/A'
        }
    },

]
const Summary = (props) => {
    const { onCancel } = { ...props }
    const [apiProgress, setApiProgress] = useState({ loading: false, ready: false, error: false })
    const [projDetailsTable, setProjDetailsTable] = useState([])
    const [teamDetailsTable, setTeamDetailsTable] = useState([])
    const [sourceDetailsTable, setSourceDetailsTable] = useState([])
    const { projectDetails } = useNextGenCreateProject();
    const umail=localStorage.getItem("email")
    const getDetails = async (e) => {
        try {
            setApiProgress({ loading: true, ready: false, error: false })
            let project_input = { entity: "project", id: projectDetails.project_id , "user":umail}//{ entity: "team", id: "83f153" , "user": "shiva.prasad@nutanix.com"}
            const proj_data = await axios.post(`${SERVER_URL}/listProjectsModel`, project_input)
            let proj_details_table = proj_data.data.data.map((e, index) => {
                return ({
                    key: index,
                    Id: e[0],
                    project_name: e[1],
                    description: e[2]
                })
            })
            setProjDetailsTable(proj_details_table)
            let team_input = {
                entity: "Project",
                id: projectDetails.project_id || ""
            }
            const team_data = await axios.post(`${SERVER_URL}/listMembersModel`, team_input)
            let team_details_table = team_data.data.data.map((e, index) => {
                return ({
                    key: index,
                    name: e[0].split('@')[0].split('.').map(e => {
                        return e.charAt(0).toUpperCase() + e.slice(1);
                    }).join(' '),
                    email: e[0],
                    access: e[1]
                })
            })
            setTeamDetailsTable(team_details_table)
            let source_input = { project_id: projectDetails.project_id }
            const source_data = await axios.post(`${SERVER_URL}/listDataSourceModel`, source_input);
            let source_details_table = source_data.data.data.map((e, index) => {
                return ({
                    key: index,
                    name: e[0],
                    database: e[3]?.database,
                    database_type: e[1],
                    host_name: e[3]?.host
                })
            })
            setSourceDetailsTable(source_details_table)
            setApiProgress({ loading: false, ready: true, error: false })

        } catch (e) {
            setApiProgress({ loading: false, ready: false, error: apiResponseError(e) })
        }
    }
    useEffect(() => {
        getDetails()
    // eslint-disable-next-line 
    }, [])
    return (
        <>

            {apiProgress.error && <Alert message={apiProgress.error} type="error" closable />}<br />
            <Collapse defaultActiveKey={['1']} ghost>
                <Panel header={<CollapseHeader name="Project Details" />} key="1">
                    <p>{<CollapseContent columns={projectColumns} data={projDetailsTable} status={apiProgress.loading && 'loading'} />}</p>
                </Panel>
                <Panel header={<CollapseHeader name="Team Details" />} key="2">
                    <p>{<CollapseContent columns={teamColumns} data={teamDetailsTable} status={apiProgress.loading && 'loading'} />}</p>
                </Panel>
                <Panel header={<CollapseHeader name="Source" />} key="3">
                    <p>{<CollapseContent columns={sourceColumns} data={sourceDetailsTable} status={apiProgress.loading && 'loading'} />}</p>
                </Panel>
            </Collapse>

            <div style={{ height: "1px", background: "#e4dddd", marginTop: "72px" }}></div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "right", marginTop: "20px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "right", gap: "16px", marginRight: "0px", marginTop: "16px" }}>
                    <Button data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-summary-close-btn`}
                        onClick={onCancel}
                    >
                        {" "}
                        OK
                    </Button>

                </div>
            </div>
        </>
    );
};
const CollapseHeader = (props) => {
    const { name } = { ...props }
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <p style={{ color: "black", fontWeight: 600, fontSize: "13px" }}>{name}</p>
        </div>
    )
}

const CollapseContent = (props) => {
    const { columns, data, status } = { ...props }
    return (
        <>
            {status === "loading" ?
                <Spin>
                    <Table columns={columns} dataSource={data} pagination={false} />
                </Spin>
                :
                <Table columns={columns} dataSource={data} pagination={false} />
            }
        </>
    )
}

export default Summary;


