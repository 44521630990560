import Papa from "papaparse";

import { PutObjectCommand, S3 } from "@aws-sdk/client-s3";

import { fetchVaultData } from '../Service/fileUploadUtility/index';



export const fileUploadToNuO = async (file) => {
    await fetchVaultData()
        .then(async resp => {
            const { data } = resp;
            const token = data.data.data.data;
            const client = new S3({
                credentials: {
                    accessKeyId: token.ACCESS_KEY,
                    secretAccessKey: token.SECRET_KEY,
                },
                region: 'us-east-1',
                endpoint: token.ENDPOINT_URL,
                forcePathStyle: true,
            });

            const command = new PutObjectCommand({
                Bucket: token.BUCKET_NAME,
                Key: file.name,
                Body: file,
            });

            try {
                await client.send(command);
            } catch (err) {
                throw new Error({ message: err });
            }
        })
        .catch(err => {
            throw new Error({ message: err });
        });
};

export const convertFileIntoJson = (file, selectedTemplate) => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            complete: function (results) {
                const res = handleValidation(results.data, selectedTemplate);
                if (res.status) {
                    return resolve(true);
                } else if (!res.status && res.fileError) {
                    return reject({ err: 'Validation failed', fileError: true, rowError: false,corruptRecords: res.corruptRecords  })
                }
                return reject({ err: "Validation failed ", fileError: false, rowError: true, corruptRecords: res.corruptRecords });
            },
            header: true
        });
    });
};

export const handleValidation = (parsed, activeTemplate) => {
    try {
        let corruptRecords = [];
        let validation_required = [];
        
        const response =parsed;
        activeTemplate?.Columns.forEach(el => {
            if (el.validation_required) {
                validation_required.push(el);
            }
        })
         
        // Extract valid keys (name values) from api response columns data
            const schemaNames = activeTemplate?.Columns?.map(item => (item?.name?.trim().toLowerCase()));// columns from api

            // Extract keys from uploaded file
            const dataKeys = [...new Set(parsed.flatMap(item => Object.keys(item).map(key => key.trim().toLowerCase())))];
            const allNonMatchingKeys = dataKeys.filter(key => !schemaNames.includes(key));
            const keyValuePairs = allNonMatchingKeys?.map(key => ({
                title: key,
                key: key,
                errorMessage: (
                    <span>
                      the <span style={{ fontWeight: 'bold' }}>{key}</span> column is missing in the template. Please reach out to the Template Admin for assistance
                    </span>
                  ),
                  fileError:true
            }));
            // Find keys that are in the uploaded file but not in api response columns data
            const missingSchemaKeys = schemaNames.filter(key => !dataKeys.includes(key.trim().toLowerCase()));
            const schemaValueMissing = missingSchemaKeys.map(key => ({
                title: key,
                key: key,
                errorMessage: (
                    <span>
                        the  <span style={{fontWeight: 'bold'}} >{key}</span> column is missing in your file. Please use the system-generated template, add the required data, and re-upload.
                    </span> 
                ),
                fileError:true
            }));
        corruptRecords=[...schemaValueMissing,...keyValuePairs]
        const isValid = validateData(response, activeTemplate, corruptRecords, validation_required);
        if (!isValid.status && isValid.fileError) {
            // trigger email with corrupt records 
        }

        if (!isValid.status && isValid.rowError) {
            // trigger email with file mismatch error
        }
        return isValid;
    } catch (err) {
        return false;
    }
};

const headerValidation = (object, columns) => {
    if (object.length < 1) return false;

    const headers = Object.entries(object[0]);

    if (headers.length !== columns.length) return false;

    let error = [];
    let isValid = true;
    for (let i = 0; i < headers.length; i++) {
        if (headers[i][0]?.trim().toLowerCase() !== columns[i].name) {
            error.push(i);
            isValid = false;
        }
    }

    return isValid;
}

export const validateData = (data, template, corruptRecords, validation_required) => {
    let isValid = {
        status: true,
        fileError: false,
        rowError: false,
        corruptRecords: corruptRecords
    };

    if (!headerValidation(data, template.Columns)) {
        isValid = {
            status: false,
            fileError: true,
            rowError: false,
            corruptRecords: corruptRecords
        }
        return isValid;
    }

    data.forEach((e, idx) => {
        if (idx < data.length - 1 && !checkDataIsValid(template.Columns, e, data, idx, corruptRecords, validation_required)) {
            isValid = {
                status: false,
                fileError: false,
                rowError: true,
                corruptRecords: corruptRecords
            }
        }
    });
    return isValid;
}

export const checkDataIsValid = (templateColumn, objects, data, idx, corruptRecords, validation_required) => {
    // return true if value exist, if not add it into corruptRecords
    // return true; // @asfahan requested to avoid data validation 

    for (const { name } of Object.values(validation_required)) {
        let isValid = false
        let errorMessage = ''

        const currentValue = objects[name];
        if (!currentValue) {
            if (name === 'chief_of_staff') {
                // do nothing
            } else {
                isValid = false
                errorMessage = 'data should not be empty'
            }

        } else {
            isValid = true;
        }

        if (!isValid) {
            corruptRecords.push({ key: idx + 1, errorMessage, column: name });
        }

    }
    if (corruptRecords.length > 0) {
        return false;
    }
    return true;
}
