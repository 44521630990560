import React, { useEffect, useState } from 'react';
import {
    ContainerLayout,
    FocusTrap,
    PopupProvider,
    StackingLayout,
    FilterIcon,
    Form,
    FormSection,
} from '@nutanix-ui/prism-reactjs';

import '../../styles.css';
import ViewAttributesFilter from './viewAttributesMenu';
import { getAvailableAttributes } from '../../../../Service/dgo';

const RenderPopupContent = ({isVisible, handleApplyFilter, setAttributesList, handleClearAttributeList, availableAttributes}) => {

    return <div className='viewAttributesContainer'>
        <ContainerLayout width={"100%"} zIndex>
            <FocusTrap isActive={isVisible}>
                <StackingLayout>
                    <Form>
                        <FormSection>
                            <ViewAttributesFilter handleApplyFilter={handleApplyFilter} setAttributesList={setAttributesList} handleClearAttributeList={handleClearAttributeList} availableAttributes={availableAttributes} />
                        </FormSection>
                    </Form>
                </StackingLayout>
            </FocusTrap>
        </ContainerLayout>
    </div>
}

export default function ViewAttributesList({handleApplyFilter}) {
    const [isOpen, setIsOpen] = useState(false);
    const [attributesList, setAttributesList] = useState([]);
    const [availableAttributes, setAvailableAttributes] = useState([]);

    useEffect(() => {
        getAvailableAttributes()
        .then(res => {
            setAvailableAttributes(prevState => res);
        })
        .catch(err => {
            window.location.replace('/error')
        })
    }, []);

    const handleSaveAttributeList = () => {
        setIsOpen(prevState => false);
        handleApplyFilter(prevState => attributesList)
    }

    const handleClearAttributeList = () => {
        setIsOpen(prevState => false);
        handleApplyFilter(prevState => [])
    }

    return (
        <StackingLayout>
            <PopupProvider
                action={['click']}
                popup={<RenderPopupContent isOpen={isOpen} handleApplyFilter={handleSaveAttributeList} setAttributesList={setAttributesList} handleClearAttributeList={handleClearAttributeList} availableAttributes={availableAttributes} />}
                popupPlacement="bottomRight"
                popupVisible={isOpen}
                onPopupVisibleChange={(visible) => setIsOpen(visible)}
                popupStyle={{
                    zIndex: 1,
                    border: '1px solid #B8BFCA',
                    display: isOpen ? 'flex' : 'none',
                    marginTop: '1rem',
                    borderRadius: '5px',
                    width: '15%',
                    backgroundColor: 'white'
                }}
            >
                <div className="viewAttributesDGOContent">
                    <p>View Changes by Attributes</p>
                    <div>
                        <FilterIcon />
                    </div>
                </div>
            </PopupProvider>
        </StackingLayout>
    );
};