import React, { useEffect, useState } from 'react';
import { Button, Alert, Divider, MultiSelect } from '@nutanix-ui/prism-reactjs';

import { getCompleteAttributeList } from './attributeList';

export default function ViewAttributesFilter({ handleApplyFilter, setAttributesList, handleClearAttributeList, availableAttributes }) {

  const [selectedRows, setSelectedRows] = useState([]);

  const [filterListData, setFilterListData] = useState([]);

  useEffect(() => {
    setFilterListData(prevState => getCompleteAttributeList(availableAttributes));
  }, [availableAttributes])

  const handleOnSelectedChange = (selectedRows) => {
    setSelectedRows(prevState => selectedRows);
    setAttributesList(prevState => selectedRows);
  }

  const handleSubmitBtn = () => {
    setAttributesList(prevState => selectedRows);
    handleApplyFilter();
  }

  const handleClearBtn = () => {
    setSelectedRows(prevState => []);
    setAttributesList(prevState => []);
    handleClearAttributeList();
  }

  const props = {
    rowsData: filterListData,
    selectedRows: selectedRows,
    onSelectedChange: handleOnSelectedChange,
  };

  return (
    <div style={{ width: '100%', minWidth: '100%', padding: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <span style={{
        color: 'gray',
        fontSize: 'smaller'
      }}>Note: Please Click on Apply Filter to view changes</span>
      <MultiSelect {...props} />
      <Divider />
      <div className='attributeBtnContainer'>
        <Button onClick={handleClearBtn}>Reset</Button>
        <Button onClick={handleSubmitBtn}>Save</Button>
      </div>
    </div >
  );

}